import React, { useMemo } from "react";

import { VulnerabilityCounter } from "components/pages/Dashboard/snippets/LastVulnerabilities/VulnerabilityCounter";
import {
  Vulnerability,
  VulnerabilityType,
} from "components/pages/Dashboard/core/dashboard.type";
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { COLORS_CONFIG } from "../../core/dashboard";

const useStyles = makeStyles<Theme, { color?: string }>((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.white.main,
      margin: "0 0 20px 0",
      padding: "24px 32px",
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
    },
    title: {
      fontSize: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    addTitle: {
      textTransform: "capitalize",
      marginLeft: "5px",
      color: (props: any) => (props.color ? props.color : "inherit"),
    },
  })
);

type VulnerabilitiesListProps = {
  vulnerabilities: Vulnerability[];
  isLoading: boolean;
};

const LastVulnerabilities = ({
  vulnerabilities,
  isLoading,
}: VulnerabilitiesListProps) => {
  const [t] = useTranslation();
  const lvl = useMemo(() => {
    return [
      VulnerabilityType.CRITICAL,
      VulnerabilityType.HIGH,
      VulnerabilityType.MEDIUM,
      VulnerabilityType.LOW,
    ].find((type) => {
      return vulnerabilities.some((r) => r.type === type && r.count !== 0);
    });
  }, [vulnerabilities]);

  const styles = useStyles({
    color: lvl ? COLORS_CONFIG[lvl as string] : "inherit",
  });

  return (
    <Box mt={1} mb={1} className={styles.container}>
      {lvl ? (
        <>
          <Box display={"flex"} marginBottom={"10px"}>
            <Typography className={styles.title}>
              {t("thread_level")}:
            </Typography>
            <Typography className={classnames(styles.title, styles.addTitle)}>
              {t(`lvl.${lvl?.toLowerCase()}`)}
            </Typography>
          </Box>
          <Box marginBottom={"10px"}>
            <Typography>
              {t(`pageVulnerability:description.${lvl?.toLowerCase()}`)}
            </Typography>
          </Box>
        </>
      ) : (
        <Box margin={"10px 0"}>
          <Typography className={classnames(styles.title)}>
            {t("common:emptyScans")}
          </Typography>
        </Box>
      )}
      <Grid container spacing={2}>
        {vulnerabilities.map(({ type, count }, index) => (
          <Grid item lg={3} md={3} xs={6} key={index}>
            <VulnerabilityCounter
              lvl={lvl ? lvl : ""}
              type={type}
              count={count}
              isLoading={isLoading}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LastVulnerabilities;
