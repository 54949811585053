export type Subscriptions = {
  id: SubscriptionType;
  rules: {
    scansPerMonth: number;
    maxHosts: number;
  };
};

export enum SubscriptionType {
  Basic = "Basic",
  Premium = "Premium",
  Free = "Free",
  Single = "Single",
  Base = "Base",
  Standard = "Standard",
  Extended = "Extended",
  Corporate = "Corporate"
}

export const SUBSCRIPTIONS: { [key in SubscriptionType]: Subscriptions } = {
  [SubscriptionType.Basic]: {
    id: SubscriptionType.Basic,
    rules: {
      scansPerMonth: 100,
      maxHosts: 10,
    },
  },
  [SubscriptionType.Premium]: {
    id: SubscriptionType.Premium,
    rules: {
      scansPerMonth: Infinity,
      maxHosts: 10,
    },
  },
  [SubscriptionType.Free]: {
    id: SubscriptionType.Free,
    rules: {
      scansPerMonth: 50,
      maxHosts: 10,
    },
  },
  [SubscriptionType.Single]: {
    id: SubscriptionType.Single,
    rules: {
      scansPerMonth: 10,
      maxHosts: 1,
    },
  },
  [SubscriptionType.Base]: {
    id: SubscriptionType.Base,
    rules: {
      scansPerMonth: 50,
      maxHosts: 5,
    },
  },
  [SubscriptionType.Standard]: {
    id: SubscriptionType.Standard,
    rules: {
      scansPerMonth: 100,
      maxHosts: 10,
    },
  },
  [SubscriptionType.Extended]: {
    id: SubscriptionType.Extended,
    rules: {
      scansPerMonth: 1000,
      maxHosts: 50,
    },
  },
  [SubscriptionType.Corporate]: {
    id: SubscriptionType.Corporate,
    rules: {
      scansPerMonth: 3000,
      maxHosts: 100,
    },
  },
};

export enum SettingsActionTypes {
  Load = "[SETTINGS] Load Settings",
  LoadSuccess = "[SETTINGS] Load Settings Success",
  LoadFailed = "[SETTINGS] Load Settings Failed",
  TourStatus = "[SETTINGS] Tour toggle",
  LoadRequestStatus = "[SETTINGS] Request status load",
  LoadUserInfo = "[SETTINGS] Load user info",
}
