import { createReducer } from "../../utils/createReducers";
import { SET_USER } from "../constants/user";

export type InfoProps = {
  email?: string;
  email_verified?: boolean;
  sub: string;
};

type State = {
  info: Partial<InfoProps>;
};

const initialState: Partial<State> = {
  info: {},
};

export default createReducer(initialState, {
  [SET_USER]: (state, { payload }: any) => ({
    ...state,
    info: payload,
  }),
});
