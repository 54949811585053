import React from "react";
import { useKeycloak } from "@react-keycloak/web";

const KeycloakHOC: React.FC<any> = ({ children }) => {
  const { initialized, keycloak } = useKeycloak();
  if (initialized) {
    return React.cloneElement(children, {
      initialized: initialized,
      keycloak,
    });
  }
  return null;
};

export default KeycloakHOC;
