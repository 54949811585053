import {
  VulnerabilitiesByScan,
  Vulnerability,
  VulnerabilityType,
} from "../core/dashboard.type";
import { retrieveActiveUserToken } from "../../../core/auth";
import { INITIAL_VULNERABILITIES } from "../core/dashboard";
import {
  getLatestAssetPeriod,
  getVulnerabilitiesStats,
} from "../../../../api/api";

type XSignalResponse<T> = {
  data: T;
  message: string;
  status: string;
};

type VulnerabilitiesResponse = {
  count: number;
  severity: VulnerabilityType;
  assetName?: string;
  scanId: string;
};

type VulnerabilitiesResponseDictionary = {
  [scanId: string]: {
    vulnerabilities: VulnerabilitiesResponse[];
    assetName?: string;
  };
};

export const fetchVulnerabilitiesStats = (): Promise<Vulnerability[] | any> => {
  return getVulnerabilitiesStats()
    .then((response) => response.data)
    .then((response: Pick<VulnerabilitiesResponse, "count" | "severity">[]) =>
      createVulnerabilities(response)
    );
};

export const fetchVulnerabilityList = (): Promise<VulnerabilitiesByScan[]> => {
  return getLatestAssetPeriod()
    .then((response) => response.data)
    .then((response: VulnerabilitiesResponse[]) =>
      createVulnerabilitiesResponseDictionary(response)
    )
    .then((responseByScanId: VulnerabilitiesResponseDictionary) =>
      Object.keys(responseByScanId).map((scanId) => ({
        index: scanId,
        assetName: responseByScanId[scanId].assetName,
        vulnerabilities: createVulnerabilities(
          responseByScanId[scanId].vulnerabilities
        ),
      }))
    );
};

const createVulnerabilities = (
  response: Pick<VulnerabilitiesResponse, "count" | "severity">[]
): Vulnerability[] => {
  return INITIAL_VULNERABILITIES.map((initialValue) => {
    const data: Pick<VulnerabilitiesResponse, "count" | "severity">[] =
      response || [];
    const vulnerabilityResponse:
      | Pick<VulnerabilitiesResponse, "count" | "severity">
      | undefined = data.find(
      (vulnerability) => vulnerability.severity === initialValue.type
    );

    return vulnerabilityResponse
      ? mapResponseToVulnerability(vulnerabilityResponse)
      : initialValue;
  });
};

const mapResponseToVulnerability = (
  responseItem: Pick<VulnerabilitiesResponse, "count" | "severity">
): Vulnerability => {
  return {
    count: responseItem.count,
    type: responseItem.severity,
  };
};

const createVulnerabilitiesResponseDictionary = (
  data: VulnerabilitiesResponse[]
): VulnerabilitiesResponseDictionary => {
  if (data) {
    return data.reduce((acc, item) => {
      if (!acc[item.scanId]) {
        acc[item.scanId] = {
          vulnerabilities: [],
          assetName: item.assetName,
        };
      }

      acc[item.scanId].vulnerabilities.push(item);

      return acc;
    }, <VulnerabilitiesResponseDictionary>{});
  } else {
    let acc: VulnerabilitiesResponseDictionary = {};
    acc[""] = {
      vulnerabilities: [],
      assetName: "",
    };
    return acc;
  }
};
