import { Box, Grid } from "@material-ui/core";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { setNotification } from "store/action/notification";
import {
  fetchVulnerabilitiesStats,
  fetchVulnerabilityList,
} from "./data/dashboard.data";
import {
  INITIAL_VULNERABILITIES,
  INITIAL_VULNERABILITIES_LIST,
} from "./core/dashboard";
import LastVulnerabilities from "./snippets/LastVulnerabilities";
import VulnerabilitiesChart from "./snippets/VulnerabilitiesChart";
import DashboardCommonInfo from "./snippets/DashboardCommonInfo/DashboardCommonInfo";
import LambdaList from "./snippets/LambdaList";
import TopAssets from "./snippets/TopAssets";
import VulnerabilitiesDate from "./snippets/VulnerabilitiesDate";
import { Asset } from "../../../models";

type DashboardProps = {
  getVulnerabilitiesThunk: () => void;
  vulnerData: any[];
  vulnerTime: any[];
  assets: Asset[];
  loadScans: () => void;
  scans: any;
};

const Dashboard: FC<DashboardProps> = ({
  getVulnerabilitiesThunk,
  vulnerData,
  vulnerTime,
  assets,
  loadScans,
  scans,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [vulnerabilitiesByScan, setVulnerabilitiesByScan] = useState(
    INITIAL_VULNERABILITIES_LIST
  );
  const [lastVulnerabilities, setLastVulnerability] = useState(
    INITIAL_VULNERABILITIES
  );
  const dispatch = useDispatch();
  const ref = useRef(null);

  const hasVulnerabilities = useMemo(() => {
    return vulnerabilitiesByScan.every((item) =>
      item.vulnerabilities.every((elem) => elem.count === 0)
    );
  }, [vulnerabilitiesByScan]);

  const typeShowModal = useMemo(() => {
    let response;
    switch (true) {
      case !Boolean(scans.length) && Boolean(assets.length):
        response = "showScan";
        break;
      case !Boolean(scans.length) && !Boolean(assets.length):
        response = "showAsset";
        break;
      default:
        response = "default";
    }
    return response;
  }, [assets, scans]);

  useEffect(() => {
    loadScans();
    getVulnerabilitiesThunk();
    setIsLoading(true);
    Promise.all([fetchVulnerabilityList(), fetchVulnerabilitiesStats()])
      .then(([list, last]) => {
        setLastVulnerability(last);
        setVulnerabilitiesByScan(list);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch(
          setNotification({ text: error.message, type: "error", open: true })
        );
      });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("tariff")) {
      const tariff = localStorage.getItem("tariff");
      if (tariff) {
        if (localStorage.getItem("isFirst") === "true") {
          window.open(`${process.env.REACT_APP_URL_BUY}?tariff=${tariff}`);
        }
      }
    }
  }, [localStorage]);

  return (
    <Box marginTop={"20px"}>
      <Grid container spacing={2}>
        <Grid item lg={8} md={12} xs={12}>
          <LastVulnerabilities
            vulnerabilities={lastVulnerabilities}
            isLoading={isLoading}
          />
          <VulnerabilitiesChart
            vulnerabilityList={vulnerabilitiesByScan}
            typeShowModal={typeShowModal}
            isLoading={isLoading}
          />
          <Box marginTop={"16px"}>
            <VulnerabilitiesDate
              typeShowModal={typeShowModal}
              data={vulnerTime}
              hasVulnerabilities={hasVulnerabilities}
            />
          </Box>
          <Box marginTop={"16px"}>
            <TopAssets
              typeShowModal={typeShowModal}
              data={vulnerData}
              hasVulnerabilities={hasVulnerabilities}
            />
          </Box>
        </Grid>

        <Grid item lg={4} md={12} xs={12}>
          <Box className={"joyride"}>
            <DashboardCommonInfo ref={ref} />
          </Box>
          <LambdaList />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
