import { ActionCreator } from "redux";

import { getAboutMe, getMaxScans } from "../../api/api";
import { SettingsActionTypes } from "../constants/settings";
import { api } from "../../api/request";
import { setNotification } from "store/action/notification";
import i18n from "i18next";

export const loadSettings: ActionCreator<void> = () => (dispatch: any) => {
  dispatch({ type: SettingsActionTypes.Load });
  let responsed = {};
  getAboutMe()
    .then((response) => {
      responsed = {
        ...responsed,
        ...response.data,
      };
    })
    .then(() => {
      getMaxScans()
        .then((val) => {
          responsed = {
            ...responsed,
            ...val.data,
          };
        })
        .finally(() => {
          dispatch(
            loadSettingsSuccess({
              ...responsed,
            })
          );
        });
    })
    .catch((error) => {
      loadSettingsFailed("failed fetch");
    })
    .finally(() => {
      dispatch(
        loadSettingsSuccess({
          ...responsed,
        })
      );
    });
};

export const loadSettingsSuccess: ActionCreator<any> = (settings: any) => ({
  type: SettingsActionTypes.LoadSuccess,
  payload: settings,
});

export const loadSettingsFailed: ActionCreator<any> = (error: any) => ({
  type: SettingsActionTypes.LoadFailed,
  payload: error,
});

export const getSettings: ActionCreator<any> = () => async (dispatch: any) => {
  try {
    const resp = await api.get(`/users/profile`);
    if (resp.status === 200) {
      dispatch({
        type: SettingsActionTypes.LoadUserInfo,
        payload: resp.data,
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const putSettings: ActionCreator<any> =
  (params: any) => async (dispatch: any) => {
    try {
      dispatch(changeLoadRequest(true));
      const resp = await api.put("/users/profile", params);

      if (resp.status === 200) {
        dispatch(
          setNotification({
            type: "success",
            open: true,
            text: i18n.t(["pageSettings:changeSuccess"]),
          })
        );

        dispatch(getSettings());
        const respMe = await getAboutMe();
        if (respMe?.data) {
          dispatch(loadSettingsSuccess(respMe.data));
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(changeLoadRequest(false));
    }
  };

export const changeLoadRequest: ActionCreator<any> =
  (payload: any) => async (dispatch: any) => {
    dispatch({
      type: SettingsActionTypes.LoadRequestStatus,
      payload,
    });
  };
