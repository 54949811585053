import { forwardRef } from "react";

const Loader = (props: any) => {
  return (
    <div
      className="loader"
      ref={props.forwardedRef ? props.forwardedRef : null}
    >
      <div className="loader-inner line-scale">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const LayoutLoader = forwardRef((props, ref) => {
  return <Loader {...props} ref={ref} />;
});

export default Loader;
