import { createReducer } from "../../utils/createReducers";
import {
  GET_SUBPATHS,
  GET_SUBPATHS_SCANS,
  CHOOSE_SUBPATHS,
  GET_SUBPATHS_FUNC,
  GET_SUBPATHS_RADIO,
  SET_SUBPATH_ALLFUNC_TYPE,
  SET_SUBPATH_CONFIG_TYPE_CHECK,
  SET_SUBPATH_CONFIG_TYPE,
  START_SUBPATH_SCAN,
  SET_ACTIVE_SUBPATH_SCAN,
  REMOVE_CURRENT_SUBPATH_SCAN,
  GET_MAX_HOSTS_SUBNETS,
  SET_SUBNETS_SERVICE_ACTIVE,
  GET_SUBNETS_SERVICE_DATA,
  GET_SUBNETS_SERVICE_DATA_DETAILS,
  GET_SUBNETS_SERVICE_DATA_VULNERABILITIES,
  GET_SUBNETS_VULNERABILITY_ID,
} from "../constants/subpaths";
import { SubnetModel } from "../../types/common";
import { DateTime } from "luxon";

type stateProps = {
  data: SubnetModel[];
  scans: any[];
  choosen: any;
  scanType: any;
  checkedScanType: any;
  catalog: any[];
  catalogRadio: any[];
  choosenScanType: any;
  allfunc: any;
  checkedConfigScan: any[];
  startTime: any;
  activeScan: any;
  currentScan: any;
  maxHosts: any;
  service: {
    data: any[];
    active: any;
  };
  servicesData: any[];
  vulnerabilitiesData: any[];
  vulnerabilityById: any;
};

const initState: stateProps = {
  data: [],
  scans: [],
  choosen: {},
  scanType: {
    label: "Quick scan",
    value: "quick",
  },
  choosenScanType: {},
  checkedScanType: {},
  catalog: [],
  catalogRadio: [],
  allfunc: [],
  checkedConfigScan: [],
  activeScan: {},
  startTime: null,
  currentScan: null,
  maxHosts: null,
  service: {
    data: [],
    active: null,
  },
  servicesData: [],
  vulnerabilitiesData: [],
  vulnerabilityById: {},
};

export default createReducer(initState, {
  [GET_SUBPATHS]: (state, { payload }: any) => ({
    ...state,
    data: payload.sort((a: any, b: any) => b.id - a.id),
  }),
  [GET_SUBPATHS_SCANS]: (state, { payload }: any) => ({
    ...state,
    scans: payload.sort((a: any, b: any) => {
      return (
        DateTime.fromISO(b.createdAt).toMillis() -
        DateTime.fromISO(a.createdAt).toMillis()
      );
    }),
  }),
  [CHOOSE_SUBPATHS]: (state, { payload }: any) => ({
    ...state,
    choosen: payload,
  }),
  [GET_SUBPATHS_FUNC]: (state, { payload }: any) => ({
    ...state,
    catalog: payload,
  }),
  [GET_SUBPATHS_RADIO]: (state, { payload }: any) => ({
    ...state,
    catalogRadio: payload,
  }),
  [SET_SUBPATH_ALLFUNC_TYPE]: (state, { payload }: any) => ({
    ...state,
    allfunc: payload,
  }),
  [SET_SUBPATH_CONFIG_TYPE_CHECK]: (state, { payload }: any) => ({
    ...state,
    checkedConfigScan: payload,
  }),
  [SET_SUBPATH_CONFIG_TYPE]: (state, { payload }: any) => ({
    ...state,
    choosenScanType: payload,
  }),
  [START_SUBPATH_SCAN]: (state, { payload }: any) => ({
    ...state,
    startTime: payload,
  }),
  [SET_ACTIVE_SUBPATH_SCAN]: (state, { payload }: any) => ({
    ...state,
    activeScan: {
      ...state.activeScan,
      [payload]: true,
    },
    currentScan: payload,
  }),
  [REMOVE_CURRENT_SUBPATH_SCAN]: (state, { payload }: any) => ({
    ...state,
    activeScan: {
      ...state.activeScan,
      [payload]: false,
    },
    currentScan: null,
  }),
  [GET_MAX_HOSTS_SUBNETS]: (state, { payload }: any) => ({
    ...state,
    maxHosts: payload,
  }),
  [GET_SUBNETS_SERVICE_DATA]: (state, { payload }: any) => ({
    ...state,
    service: {
      ...state.service,
      data: payload,
    },
  }),
  [SET_SUBNETS_SERVICE_ACTIVE]: (state, { payload }: any) => ({
    ...state,
    service: {
      ...state.service,
      active: payload,
    },
  }),
  [GET_SUBNETS_SERVICE_DATA_DETAILS]: (state, { payload }: any) => ({
    ...state,
    servicesData: payload,
  }),
  [GET_SUBNETS_SERVICE_DATA_VULNERABILITIES]: (state, { payload }: any) => ({
    ...state,
    vulnerabilitiesData: payload,
  }),
  [GET_SUBNETS_VULNERABILITY_ID]: (state, { payload }: any) => ({
    ...state,
    vulnerabilityById: payload,
  }),
});
