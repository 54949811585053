import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../UserContext";
import { clearActiveUserToken } from "./auth";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Box,
  makeStyles,
  Typography,
  createStyles,
  Theme,
  Hidden,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Avatar from "@material-ui/core/Avatar";
import classNames from "classnames";
import { colors } from "./static";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontSize: "14px",
      fontWeight: 500,
      color: "rgba(0,0,0,.87)",
      cursor: "pointer",
    },
    icon: {
      color: "rgba(0,0,0,.87)",
    },
    item: {
      textTransform: "capitalize",
      "&:hover": {
        background: theme.palette.pink.main,
        color: theme.palette.white.main,
      },
    },
    round: {
      borderRadius: "50%",
      overflow: "hidden",
      width: "32px",
      height: "32px",
      backgroundColor: (props: any) => colors[props.bg as string],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundTitle: {
      color: "#fff",
      fontWeight: 500,
      fontSize: "16px",
    },
    menu: {
      marginTop: "30px",
    },
    partnerContainer: {
      position: "absolute",
      right: "-35px",
      padding: "9px 40px",
      borderRadius: "3px",
      background: theme.palette.pink.main,
      transform: "rotate(45deg)",
      opacity: ".8",
      [theme.breakpoints.down("md")]: {
        padding: "6px 30px",
        right: "-27px",
        top: "10px",
      },
    },
    title: {
      color: theme.palette.textGrey.main,
      fontSize: "12px",
      fontWeight: "bold",
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
    },
    settingsContainer: {
      display: "flex",
      alignSelf: "center",
      alignItems: "center",
      paddingRight: "30px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "0",
      },
    },
  })
);

const ActiveUserBlock = ({ keycloak }: any) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [t] = useTranslation();
  const history = useHistory();
  const oktaObject = useOktaAuth();
  const { clearActiveUserProfile } = useContext(UserContext);

  const settings = useSelector((state: RootState) => state.settings);
  const style = useStyles({ bg: settings.subscriptionPlan });

  useEffect(() => {
    if (process.env.REACT_APP_SECURITY === "okta") {
      if (!oktaObject?.authState.isAuthenticated) {
        history.push("/");
      }
    }
  }, [oktaObject?.authState, history]);

  return (
    <div style={{ alignSelf: "center" }}>
      <div aria-controls="menu" onClick={(e) => setAnchor(e.currentTarget)}>
        <Box className={style.settingsContainer}>
          {settings?.groups?.[0] === "XSIG_PENTESTER" ? (
            <Box className={style.partnerContainer}>
              <Typography className={style.title}>partner</Typography>
            </Box>
          ) : null}
          <Box marginRight={"8px"}>
            {settings.img ? (
              <Avatar src={process.env.PUBLIC_URL + "konstantin.png"} />
            ) : (
              <Box className={style.round}>
                <Typography className={style.roundTitle}>
                  {settings.name ? settings?.name?.[0] : ""}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display={"flex"}>
            <Hidden mdDown={true}>
              <Typography className={style.text}>{settings.email}</Typography>
            </Hidden>
            <KeyboardArrowDownIcon className={style.icon} />
          </Box>
        </Box>
      </div>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="menu"
        open={Boolean(anchor)}
        anchorEl={anchor}
        className={style.menu}
        onClose={() => setAnchor(null)}
      >
        <MenuItem
          className={classNames(style.item, style.icon)}
          onClick={() => {
            setAnchor(null);
            history.push("/settings");
          }}
        >
          {t("settings")}
        </MenuItem>
        <MenuItem
          className={classNames(style.item, style.icon)}
          onClick={(e: any) => {
            e.preventDefault();
            if (process.env.REACT_APP_SECURITY === "okta") {
              oktaObject?.oktaAuth.tokenManager.clear();

              clearActiveUserToken();

              oktaObject?.oktaAuth.signOut();
            } else {
              keycloak.logout();
            }
            clearActiveUserProfile();
            setAnchor(null);
          }}
        >
          {t("logout")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActiveUserBlock;
