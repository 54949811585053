import { FC } from "react";
import { Box, Button, Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "280px",
      borderRadius: "6px",
      boxShadow:
        "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
      background: theme.palette.white.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "24px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    title: {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "15px",
    },
    titleDescr: {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.6)",
    },
  })
);

const StartModalComponent: FC<{
  typeShowModal: string;
  handler?: () => void;
  title: string;
  titleDescr: string;
}> = ({ typeShowModal, handler, title, titleDescr }) => {
  const [t] = useTranslation(["pageDashboard", "pageAssets", "pageAddNewScan"]);
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.titleDescr}>{titleDescr}</Typography>

      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        marginTop={"20px"}
        width={"100%"}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={(): void => {
            if (handler) {
              handler();
            }
          }}
        >
          {typeShowModal === "showAsset"
            ? t("pageAssets:controls.add_assets")
            : t("pageAddNewScan:running_scan.title")}
        </Button>
      </Box>
    </Box>
  );
};

export default StartModalComponent;
