import { ActionCreator } from "redux";
import * as constants from "../constants/user";

export const setUser: ActionCreator<any> =
  (payload) => async (dispatch: any, getState: any) => {
    dispatch({
      type: constants.SET_USER,
      payload,
    });
  };
