import { createReducer } from "utils/createReducers";
import { GET_SCHEDULED_SCANS } from "../constants/scheduled_scans";

const initState = {
  data: [],
};

export default createReducer(initState, {
  [GET_SCHEDULED_SCANS]: (state, { payload }: any) => ({
    ...state,
    data: payload,
  }),
});
