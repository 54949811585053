import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, createStyles, Link, Typography } from "@material-ui/core";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import { useTranslation } from "react-i18next";
import { HeaderContext } from "../../../context";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "20px",
      background: theme.palette.white.main,
    },
    title: {
      fontSize: "17px",
      fontWeight: 500,
      color: theme.palette.headerColor.main,
      marginLeft: "10px",
    },
    listItem: {
      maxWidth: "400px",
      width: "100%",
      cursor: "pointer",
    },
    listSubItem: {
      paddingLeft: "20px",
    },
    subTitle: {
      color: theme.palette.headerColor.main,
    },
    link: {
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
        color: theme.palette.pink.main,
      },
    },
  })
);

const InfoItem: FC<any> = ({ Icon, content }) => {
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  const history = useHistory();
  return (
    <>
      <ListItem
        component="div"
        onClick={() => setOpen(!open)}
        className={styles.listItem}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Icon />
          <Typography className={styles.title}>{content.title}</Typography>
        </Box>
        <Box marginLeft={"auto"}>
          {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
        </Box>
      </ListItem>

      <Collapse component={"li"} in={open} timeout="auto" unmountOnExit>
        <List className={styles.listSubItem}>
          {content?.child?.length
            ? content?.child?.map((elem: any, i: number) => (
                <ListItem
                  key={`info--item-child-${i}`}
                  className={styles.subTitle}
                >
                  <Link
                    className={styles.link}
                    onClick={() => {
                      history.push(`/info${elem.href}`, {
                        title: elem.title,
                        headTitle: content.title,
                      });
                    }}
                  >
                    {elem.title}
                  </Link>
                </ListItem>
              ))
            : null}
        </List>
      </Collapse>
    </>
  );
};

const InfoPage: FC<{ content: any[] }> = ({ content }) => {
  const styles = useStyles();
  const { i18n, t } = useTranslation(["info"]);
  const headerContext = useContext(HeaderContext);
  useEffect(() => {
    headerContext.setHeader(t("info:infoTitle"));
    return () => {
      headerContext.setHeader("");
    };
  }, [t]);

  return (
    <Box className={styles.container}>
      <Box>
        <Typography className={styles.title}>{t("info:title")}</Typography>
      </Box>
      <Box>
        <List component="nav">
          {content?.length
            ? content?.map((item, i: number) => (
                <InfoItem
                  key={`table-content--item-${i}`}
                  Icon={item.icon}
                  content={item[i18n.language]}
                />
              ))
            : null}
        </List>
      </Box>
    </Box>
  );
};

export default InfoPage;
