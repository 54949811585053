import { Component } from "react";
import BoundaryLayout from "../comps/Layout/BoundaryLayout";
import { Box, Button, Typography } from "@material-ui/core";
import styled from "styled-components";

import { withRouter } from "react-router-dom";

const ImageContainer = styled(Box)`
  width: 350px;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
`;

class ErrorMainBoundary extends Component<any, any> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <BoundaryLayout>
          <Box>
            <ImageContainer>
              <img src={process.env.PUBLIC_URL + "/Logo.png"} />
            </ImageContainer>

            <Box
              display="flex"
              flexDirection="column"
              alignItems={"center"}
              marginTop={"20px"}
            >
              <Typography variant="h4">Ooops....</Typography>
              <Typography variant="body1">Something goes wrong</Typography>
            </Box>

            <Box marginTop={"20px"}>
              <Box display={"flex"} justifyContent={"center"}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.props.history.push("/");
                  }}
                  style={{ marginLeft: "15px" }}
                >
                  Go to Main Page
                </Button>
              </Box>
            </Box>
          </Box>
        </BoundaryLayout>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorMainBoundary);
