import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { FC, SyntheticEvent, useEffect, useState } from "react";

export type Severity = "error" | "warning" | "info" | "success";

type ToastProps = {
  noteType: Severity;
  text: string;
  openProps: boolean;
  clearHandler: () => void;
};

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Toast: FC<ToastProps> = ({
  noteType,
  text,
  openProps,
  clearHandler = () => {
    //  void
  },
}) => {
  const [openState, setOpenState] = useState(false);

  useEffect(() => {
    if (!openState) {
      clearHandler();
    }
  }, [openState]);

  useEffect(() => {
    setOpenState(openProps);
  }, [openProps]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      key={"bottom right"}
      open={openState}
      autoHideDuration={4000}
      onClose={(event?: SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") return;
        setOpenState(false);
      }}
    >
      <Alert severity={noteType}>{text}</Alert>
    </Snackbar>
  );
};

export default Toast;
