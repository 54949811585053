import React, { PropsWithChildren } from "react";
import { Box } from "@material-ui/core";
import classNames from "classnames";

import Loader from "components/comps/icons/Loader";
import BoxContent from "./BoxContent";
import BoxTitle from "./BoxTitle";
import "./BoxView.css";

export type BoxViewProps = {
  title: string;
  isLoading?: boolean;
  style?: any;
};

const BoxView = ({
  title,
  isLoading,
  children,
  ...rest
}: PropsWithChildren<BoxViewProps>) => {
  return (
    <Box {...rest}>
      <BoxTitle>{title}</BoxTitle>
      <BoxContent
        className={classNames("box-view", {
          "box-view-loading": isLoading,
        })}
      >
        {isLoading && (
          <Box className="box-view-loading__loader">
            <Loader />
          </Box>
        )}
        {children}
      </BoxContent>
    </Box>
  );
};

export default BoxView;
