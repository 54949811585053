import React from "react";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { ColumnItem } from "../../../types/common";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Condition } from "../Condition";
import Checkbox from "@material-ui/core/Checkbox";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      overflow: "auto",
      backgroundColor: theme.palette.white.main,
      "& .MuiTableCell-root": {
        [theme.breakpoints.down("md")]: {
          padding: "6px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "10px",
        },
      },
    },
    rowBody: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#F3F4F6",
      },
    },
    arrow: {
      verticalAlign: "middle",
    },
    arrowDown: {
      transform: "rotate(180deg)",
    },
    headStyle: {
      backgroundColor: theme.palette.backGrey.contrastText,
      color: theme.palette.textGrey.contrastText,
      letterSpacing: "0.4px",
    },
    containerTable: {
      overflowX: "auto",
      width: "100%",
    },
  })
);

export const CheckBoxComponent = React.forwardRef(
  ({ indeterminate, disabled = false, ...rest }: any, ref) => {
    const defaultRef = React.useRef<any>(null);
    const resolvedRef: any = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <Checkbox inputRef={resolvedRef} {...rest} disabled={disabled} />;
  }
);

type MainTableProps = {
  columns: ColumnItem[];
  data: any[];
  pagination?: boolean;
  clickFunc?: (val: any) => void;
  checks?: boolean;
  checksHeader?: boolean;
  checkMulti?: boolean;
  selectClick?: boolean;
  selectChange?: (val: any, selected?: boolean) => void;
  selectAllChange?: (val: any[]) => void;
  checkValue?: {
    [key: string]: boolean;
  };
  checkboxProperyName?: string;
};

/**
 * @param {ColumnItem[]} columns - columns of table
 * @param {Array} data - data content of table
 * @param {Boolean} pagination - show pagination param
 * @param {Function} clickFunc - row click handler function
 * @param {Boolean} checks - showing checkbox column
 * @param {Boolean} checksHeader - showing checkbox for header table for all row check
 * @param {Boolean} checkMulti - select multi row
 * @param {Function} selectChange - uplifting checked rows to parent
 * @param {Function} selectAllChange - uplifting checked rows to parent All check
 * @param {boolean} selectClick - uplifting checked rows to parent
 * @param {object} checkValue - check checkbox value from parent
 * @param {string} checkboxPropertyName - name for id checkbox
 * @constructor
 */

const MainTable: React.FC<MainTableProps> = ({
  columns,
  data,
  pagination = false,
  clickFunc = (f) => {
    //  void function
  },
  checks = false,
  checksHeader = false,
  checkMulti = false,
  selectChange = (f) => {
    /* void func */
  },
  selectAllChange = (f) => {
    /* void func */
  },
  selectClick = false,
  checkValue = {},
  checkboxProperyName = "",
}) => {
  const [t] = useTranslation(["common"]);
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    toggleRowSelected,
    toggleAllRowsSelected,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      ...(checkboxProperyName
        ? { getRowId: (row: any) => row[checkboxProperyName] }
        : {}),
      initialState: {
        pageIndex: 0,
        selectedRowIds: checkValue || {},
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (checks) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            ...(checksHeader
              ? {
                  Header: ({
                    getToggleAllRowsSelectedProps,
                    toggleAllRowsSelected,
                  }) => (
                    <div>
                      <CheckBoxComponent
                        {...getToggleAllRowsSelectedProps()}
                        onChange={(
                          _: React.ChangeEvent<HTMLInputElement>,
                          selected: boolean
                        ) => {
                          toggleAllRowsSelected();
                          if (selected) {
                            selectAllChange(
                              selectedFlatRows.map((item) => item.original)
                            );
                          } else {
                            selectAllChange([]);
                          }
                        }}
                      />
                    </div>
                  ),
                }
              : {}),
            Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
              return (
                <div>
                  <CheckBoxComponent
                    {...row.getToggleRowSelectedProps(
                      checkMulti
                        ? {
                            onChange: () => {
                              selectChange(row.original, row.isSelected);
                              toggleRowSelected(row.id);
                            },
                          }
                        : {
                            onChange: () => {
                              selectChange(row.original);
                              toggleAllRowsSelected(false);
                              toggleRowSelected(row.id);
                            },
                          }
                    )}
                  />
                </div>
              );
            },
            width: "40px",
          },
          ...columns,
        ]);
      }
    }
  );
  const styles = useStyles();

  const paperArray = pagination ? page : rows;

  return (
    <Box className={styles.containerTable}>
      <MuiTable {...getTableProps()} className={styles.table}>
        <TableHead>
          {headerGroups.map((groups: any, i: number) => {
            return (
              <TableRow
                {...groups.getHeaderGroupProps()}
                key={`row-groups--${i}`}
              >
                {groups.headers.map((item: any, j: number) => {
                  return (
                    <TableCell
                      className={styles.headStyle}
                      style={{
                        width: item.width?.length ? `${item.width}%` : "auto",
                      }}
                      align={item?.align ?? "center"}
                      key={`cell-header-item--${j}`}
                      {...item.getHeaderProps(
                        item.colSorted && item.getSortByToggleProps()
                      )}
                    >
                      {item.render("Header")}
                      <Condition match={item.isSorted && item.colSorted}>
                        <ArrowUpwardIcon
                          fontSize={"small"}
                          className={classNames(
                            styles.arrow,
                            item.isSortedDesc && styles.arrowDown
                          )}
                        />
                      </Condition>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {paperArray.map((row: any, i: number) => {
            prepareRow(row);
            return (
              <TableRow
                onClick={() => {
                  if (selectClick) {
                    if (!checkMulti) {
                      toggleAllRowsSelected(false);
                    }
                    selectChange(row.original);
                    toggleRowSelected(row.id);
                  } else {
                    clickFunc(row.original);
                  }
                }}
                className={styles.rowBody}
                key={`row-data__${i}`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell: any, j: number) => {
                  return (
                    <TableCell
                      width={
                        cell.column.width?.length
                          ? `${cell.column.width}%`
                          : "auto"
                      }
                      align={cell?.column?.align ?? "center"}
                      {...cell.getCellProps()}
                      key={`cell-data__${j}`}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        {pagination && (
          <TablePagination
            count={data.length}
            page={pageIndex}
            onPageChange={(evt, pageNew) => {
              gotoPage(pageNew);
            }}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(evt) => {
              setPageSize(Number(evt.target.value));
            }}
            labelDisplayedRows={({ from, to, count }) => {
              return (
                "" + from + "-" + to + " " + t("common:table.of") + " " + count
              );
            }}
            labelRowsPerPage={<span>{t("common:rowPerPage")}</span>}
          />
        )}
      </MuiTable>
    </Box>
  );
};

export default MainTable;
