import { createReducer } from "@reduxjs/toolkit";
import {
  getAnalyticPorts,
  getAnalyticsAsset,
  getAnalyticsHistory,
  getAnalyticsHistoryLogs,
  getAnalyticsHistoryUnchange,
  getAnalyticStatus,
} from "../action/analytics";
import { LoadingStatus } from "../../models/LoadingStatus";
import { Asset } from "models/Asset";
import { CHOOSE_ANALYTIC_ASSET } from "../constants/analytics";

type analyticsType = {
  data: {
    content: any[];
    loading: LoadingStatus;
    error: null | Error;
  };
  assetsData: {
    content: Asset[];
    loading: LoadingStatus;
    error: null | Error;
  };
  unchangedScans: {
    content: any[];
    loading: LoadingStatus;
    error: null | Error;
  };
  unchangedLogs: {
    content: any[];
    loading: LoadingStatus;
    error: null | Error;
  };
  assetStatus: {
    status: any;
    loading: LoadingStatus;
    error: null | Error;
  };
  ports: {
    data: any;
    loading: LoadingStatus;
    error: null | Error;
  };
  choosen: any;
};

const initState: analyticsType = {
  data: {
    content: [],
    loading: LoadingStatus.Idle,
    error: null,
  },
  assetsData: {
    content: [],
    loading: LoadingStatus.Idle,
    error: null,
  },
  unchangedScans: {
    content: [],
    loading: LoadingStatus.Idle,
    error: null,
  },
  unchangedLogs: {
    content: [],
    loading: LoadingStatus.Idle,
    error: null,
  },
  assetStatus: {
    status: {},
    loading: LoadingStatus.Idle,
    error: null,
  },
  ports: {
    data: [],
    loading: LoadingStatus.Idle,
    error: null,
  },
  choosen: {},
};

export const analyticsReducer = createReducer(initState, {
  [getAnalyticsHistory.pending.type]: (state) => {
    state.data.loading = LoadingStatus.Idle;
    state.data.error = null;
  },
  [getAnalyticsHistory.fulfilled.type]: (state, { payload }) => {
    if (payload.data?.length) {
      state.data.content = payload.data;
    } else {
      state.data.content = [];
    }
    state.data.loading = LoadingStatus.Succeeded;
    state.data.error = null;
  },
  [getAnalyticsHistory.rejected.type]: (state, { payload }) => {
    state.data.loading = LoadingStatus.Failed;
    state.data.error = payload;
  },
  [getAnalyticsAsset.pending.type]: (state) => {
    state.assetsData.loading = LoadingStatus.Idle;
    state.assetsData.error = null;
  },
  [getAnalyticsAsset.fulfilled.type]: (state, { payload }) => {
    if (payload.data?.length) {
      state.assetsData.content = payload.data.map((item: Asset, i: number) => ({
        count: ++i,
        ...item,
      }));
    } else {
      state.assetsData.content = [];
    }
    state.assetsData.loading = LoadingStatus.Succeeded;
    state.assetsData.error = null;
  },
  [getAnalyticsAsset.rejected.type]: (state, { payload }) => {
    state.assetsData.loading = LoadingStatus.Failed;
    state.assetsData.error = payload;
  },
  [CHOOSE_ANALYTIC_ASSET]: (state, { payload }) => {
    state.choosen = payload;
  },
  [getAnalyticsHistoryUnchange.pending.type]: (state) => {
    state.unchangedScans.loading = LoadingStatus.Idle;
    state.unchangedScans.error = null;
  },
  [getAnalyticsHistoryUnchange.fulfilled.type]: (state, { payload }) => {
    if (payload?.data?.length) {
      state.unchangedScans.content = payload.data.map(
        (item: any, i: number) => ({
          ...item,
          countNumber: i + 1,
        })
      );
    } else {
      state.unchangedScans.content = [];
    }
    state.unchangedScans.loading = LoadingStatus.Succeeded;
    state.unchangedScans.error = null;
  },
  [getAnalyticsHistoryUnchange.rejected.type]: (state, { payload }) => {
    state.unchangedScans.loading = LoadingStatus.Failed;
    state.unchangedScans.error = payload;
  },
  [getAnalyticsHistoryLogs.pending.type]: (state) => {
    state.unchangedLogs.loading = LoadingStatus.Idle;
    state.unchangedLogs.error = null;
  },
  [getAnalyticsHistoryLogs.fulfilled.type]: (state, { payload }) => {
    if (payload?.data?.length) {
      state.unchangedLogs.content = payload.data;
    } else {
      state.unchangedLogs.content = [];
    }
    state.unchangedLogs.loading = LoadingStatus.Succeeded;
    state.unchangedLogs.error = null;
  },
  [getAnalyticsHistoryLogs.rejected.type]: (state, { payload }) => {
    state.unchangedLogs.loading = LoadingStatus.Failed;
    state.unchangedLogs.error = payload;
  },
  [getAnalyticStatus.pending.type]: (state) => {
    state.assetStatus.loading = LoadingStatus.Idle;
    state.assetStatus.error = null;
  },
  [getAnalyticStatus.fulfilled.type]: (state, { payload }) => {
    if (payload?.data) {
      state.assetStatus.status = payload.data;
    }
    state.assetStatus.loading = LoadingStatus.Succeeded;
    state.assetStatus.error = null;
  },
  [getAnalyticStatus.rejected.type]: (state, { payload }) => {
    state.assetStatus.loading = LoadingStatus.Failed;
    state.assetStatus.error = payload;
  },
  [getAnalyticPorts.pending.type]: (state) => {
    state.ports.loading = LoadingStatus.Idle;
    state.ports.error = null;
  },
  [getAnalyticPorts.fulfilled.type]: (state, { payload }) => {
    if (payload?.data?.length) {
      state.ports.data = payload.data;
    }
    state.ports.loading = LoadingStatus.Succeeded;
    state.ports.error = null;
  },
});
