import { FC } from "react";

const ScansIcon: FC<{ active: boolean }> = ({ active }) => {
  return active ? (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 9C15 9 17 11 17 13.5C17 14.38 16.75 15.21 16.31 15.9L19.39 19L18 20.39L14.88 17.32C14.19 17.75 13.37 18 12.5 18C10 18 8 16 8 13.5C8 11 10 9 12.5 9ZM12.5 11C11.837 11 11.2011 11.2634 10.7322 11.7322C10.2634 12.2011 10 12.837 10 13.5C10 14.163 10.2634 14.7989 10.7322 15.2678C11.2011 15.7366 11.837 16 12.5 16C13.163 16 13.7989 15.7366 14.2678 15.2678C14.7366 14.7989 15 14.163 15 13.5C15 12.837 14.7366 12.2011 14.2678 11.7322C13.7989 11.2634 13.163 11 12.5 11ZM4 12V14H6C6.14 15.55 6.8 16.94 7.81 18H2C0.89 18 0 17.1 0 16V2C0 0.89 0.89 0 2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V10.03C16.85 8.21 14.82 7 12.5 7C11.23 7 10.04 7.37 9.04 8H4V10H7C6.64 10.6 6.34 11.28 6.17 12H4ZM14 6V4H4V6H14Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 9C15 9 17 11 17 13.5C17 14.38 16.75 15.21 16.31 15.9L19.39 19L18 20.39L14.88 17.32C14.19 17.75 13.37 18 12.5 18C10 18 8 16 8 13.5C8 11 10 9 12.5 9ZM12.5 11C11.837 11 11.2011 11.2634 10.7322 11.7322C10.2634 12.2011 10 12.837 10 13.5C10 14.163 10.2634 14.7989 10.7322 15.2678C11.2011 15.7366 11.837 16 12.5 16C13.163 16 13.7989 15.7366 14.2678 15.2678C14.7366 14.7989 15 14.163 15 13.5C15 12.837 14.7366 12.2011 14.2678 11.7322C13.7989 11.2634 13.163 11 12.5 11ZM4 12V14H6C6.14 15.55 6.8 16.94 7.81 18H2C0.89 18 0 17.1 0 16V2C0 0.89 0.89 0 2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V10.03C16.85 8.21 14.82 7 12.5 7C11.23 7 10.04 7.37 9.04 8H4V10H7C6.64 10.6 6.34 11.28 6.17 12H4ZM14 6V4H4V6H14Z"
        fill="#a7b1c2"
      />
    </svg>
  );
};

export default ScansIcon;
