import React, { FC, useContext, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import i18next from "i18next";

import OktaSignInWidget from "./OktaSignWidget";
import { storeActiveUserToken } from "../core/auth";
import UserContext from "../../UserContext";
import { loadSettingsSuccess } from "store/action/settings";
import { getAboutMe } from "api/api";
import { updateUser } from "api/users";

const LoginOkta: FC<{ config: any } & RouteComponentProps<any>> = ({
  config,
  history,
}) => {
  const dispatch = useDispatch();
  const oktaObject = useOktaAuth();
  const userContext = useContext(UserContext);
  const onSuccess = (tokens: any) => {
    oktaObject?.oktaAuth.handleLoginRedirect(tokens);
  };
  const onError = (err: any) => {
    console.log("error logging in", err);
  };

  useEffect(() => {
    async function fetchApi() {
      if (
        oktaObject?.authState.isAuthenticated &&
        oktaObject?.authState.accessToken?.accessToken
      ) {
        storeActiveUserToken(oktaObject?.authState.accessToken?.accessToken);
        try {
          const resp = await updateUser({
            lang: i18next.language,
          });

          if (resp.status === 200) {
            const response = await getAboutMe();
            if (response?.data) {
              dispatch(
                loadSettingsSuccess({
                  ...response?.data,
                  subscription: "basic",
                })
              );
              if (response?.data?.redirectToStripe) {
                window.location.replace(`${process.env.REACT_APP_URL_BILLING}`);
                return;
              }
              history.push("/");
              userContext.setActiveUserProfile({
                jwt: oktaObject?.authState?.accessToken?.accessToken,
                name: response?.data?.name,
                email: response?.data?.email,
              });
            }
          } else {
            history.push("/loginOkta");
          }
        } catch (e) {
          oktaObject?.oktaAuth.signOut();
          console.log(e);
        }
      }
    }
    fetchApi();
  }, [oktaObject?.authState]);

  if (oktaObject?.authState.isPending) return null;

  return oktaObject?.authState.isAuthenticated ? null : (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  );
};

export default withRouter(LoginOkta);
