import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import InfoDescription from "../../../containers/InfoDescription";
import InfoPage from "../../../containers/InfoPage";

const InfoMain: FC<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <Route path={props.match.path} exact={true} component={InfoPage} />
      <Route
        // exact={true}
        path={`${props.match.path}/:id`}
        component={InfoDescription}
      />
    </Switch>
  );
};

export default InfoMain;
