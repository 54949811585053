import styled from "styled-components";

const BoxTitle = styled.div`
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: rgb(103, 106, 108);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 15px 90px 8px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
  border-radius: 2px 2px 0 0;

  :first-letter {
    text-transform: capitalize;
  }
`;

export default BoxTitle;
