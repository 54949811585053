import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import { get } from "lodash-es";

import { VULNERABILITY_CONFIG } from "../../core/dashboard";
import { VulnerabilitiesByScan } from "../../core/dashboard.type";
import BoxView from "components/shared/BoxView";
import { Box, Typography, Button } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import StartModalComponent from "../../../../comps/InfoComponents/StartModalComponent";
import { Condition } from "../../../../comps/Condition";

type VulnerabilitiesChartProps = {
  vulnerabilityList: VulnerabilitiesByScan[];
  isLoading: boolean;
  typeShowModal: string;
};

const VulnerabilitiesChart = ({
  vulnerabilityList,
  isLoading,
  typeShowModal,
}: VulnerabilitiesChartProps) => {
  const [t] = useTranslation();
  const history = useHistory();

  const series: unknown[] = vulnerabilityList.map((item, index) => ({
    name: index + 1,
    assetName: item.assetName,
    ...item.vulnerabilities.reduce(
      (acc, vulnerability) => ({
        ...acc,
        [vulnerability.type]: vulnerability.count,
      }),
      {}
    ),
  }));

  return (
    <BoxView
      title={t("pageDashboard:last_ten_scans")}
      isLoading={isLoading}
      style={{ position: "relative" }}
    >
      <Condition match={Boolean(series?.length)}>
        <div style={{ height: "400px" }}>
          <Condition match={typeShowModal !== "default"}>
            <StartModalComponent
              typeShowModal={typeShowModal}
              title={t("pageDashboard:startTitle")}
              titleDescr={
                typeShowModal === "showAsset"
                  ? t("pageDashboard:startDescription")
                  : t("pageVulnerability:findVulnerAsset")
              }
              handler={
                typeShowModal === "showAsset"
                  ? () => history.push("/assets")
                  : () => history.push("/scans")
              }
            />
          </Condition>
          <Condition match={typeShowModal === "default"}>
            <ResponsiveContainer width="100%">
              <AreaChart data={series}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip
                  labelFormatter={(d, w) => get(w, "0.payload.assetName", d)}
                />
                <XAxis dataKey="name" />
                <YAxis />
                {Object.keys(VULNERABILITY_CONFIG)
                  .reverse()
                  .map((key: string) => (
                    <Area
                      key={key}
                      type="monotone"
                      dataKey={key}
                      stackId="1"
                      fillOpacity="0.7"
                      stroke={VULNERABILITY_CONFIG[key].background}
                      fill={VULNERABILITY_CONFIG[key].background}
                    />
                  ))}
              </AreaChart>
            </ResponsiveContainer>
          </Condition>
        </div>
      </Condition>
      <Condition match={!Boolean(series?.length)}>
        <Box>
          <Typography>{t("common:notChecks")}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/assets")}
            style={{ marginTop: "20px" }}
          >
            {t("startWork")}
          </Button>
        </Box>
      </Condition>
    </BoxView>
  );
};

export default VulnerabilitiesChart;
