import styled from "styled-components";

const Tag = styled.span`
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
  border-radius: 0.25em;
  line-height: 1;
  white-space: nowrap;
`;

export const TagCount = styled.span`
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  padding: 3px 10px;
  text-shadow: none;
  border-radius: 4px;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.15px;
  text-transform: capitalize;
`;

export default Tag;
