import { createAsyncThunk } from "@reduxjs/toolkit";

import { ScanListActionTypes } from "store/constants/scanList";
import { getScans } from "api/api";
import { Scan } from "models";

export const loadScans = createAsyncThunk<Scan[]>(
  ScanListActionTypes.Load,
  async () => {
    return (await getScans()).data;
  }
);
