import React from "react";
import {
  Box,
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import { HeaderContext } from "../../../context";
import { useTranslation } from "react-i18next";
import bitbucket from "../../../mdFiles/bitbucket-pipelines.md";
import bitbucket_en from "../../../mdFiles/bitbucket-pipelines-en.md";
import github from "../../../mdFiles/github-actions.md";
import github_en from "../../../mdFiles/github-actions-en.md";
import { LinkYellow } from "../CatalogPerforms/components/Styled";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: theme.palette.white.main,
      padding: "24px",
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        padding: "14px",
      },
      "& code": {
        [theme.breakpoints.down("md")]: {
          fontSize: "11px",
          wordBreak: "break-all",
        },
      },
    },
    title: {
      fontSize: "18px",
      color: "#000",
      marginBottom: "10px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    linkGrey: {
      color: theme.palette.textGrey.main,
      fontSize: "18px",
      textDecoration: "underline",
      "&.MuiLink-root:hover": {
        color: "inherit",
        textDecoration: "none",
      },
      "&:visited": {
        color: "inherit",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        wordBreak: "break-all",
      },
    },
    linkYellow: {
      color: theme.palette.pink.main,
      fontSize: "14px",
      textDecoration: "underline",
      "&.MuiLink-root:hover": {
        color: "inherit",
        textDecoration: "none",
      },
      "&:visited": {
        color: theme.palette.pink.main,
      },
    },
    // title: {
    //   fontSize
    // }
  })
);

const mdsGithubs: {
  [key: string]: any;
} = {
  en: github_en,
  ru: github,
};

const mdsBitbucket: {
  [key: string]: any;
} = {
  en: bitbucket_en,
  ru: bitbucket,
};

const ApiPage: React.FC = () => {
  const [links, setLinks] = React.useState<any>({
    id: false,
    secret: false,
  });
  const [mds, setMds] = React.useState<any>({});
  const [mds2, setMds2] = React.useState<any>({});
  const { i18n, t } = useTranslation(["common"]);
  const context = React.useContext(HeaderContext);
  const styles = useStyle();

  React.useEffect(() => {
    context.setHeader(t("common:api.title"));
    fetch(mdsBitbucket[String(i18n.language)])
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setMds({
          ...mds,
          bitbucket: text,
        });
      });

    fetch(mdsGithubs[String(i18n.language)])
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setMds2({
          ...mds2,
          github: text,
        });
      });

    return () => {
      context.setHeader("");
    };
  }, [i18n]);
  return (
    <Box className={styles.container}>
      <Box>
        <Typography className={styles.title}>
          {t("common:swaggerLink")}:
        </Typography>
        <Link
          href={"https://app.swaggerhub.com/apis/xsignal/api.xsignal.io/1.0.0"}
          target={"_blank"}
          className={styles.linkGrey}
        >
          https://app.swaggerhub.com/apis/xsignal/api.xsignal.io/1.0.0
        </Link>
      </Box>

      <Box marginTop={"30px"}>
        <Typography className={styles.title}>
          {t("common:idAccess")}:
        </Typography>

        <Box display={"flex"} alignItems={"center"}>
          <Typography>Client ID:</Typography>
          <a
            className={"link_copy"}
            onClick={() => {
              navigator.clipboard.writeText("0oa2zneo3ePwO2xK45d7");
              setLinks({
                ...links,
                id: true,
              });
            }}
          >
            {
              links.id ? t("common:copied") : t("common:copy")
              // links.id ? 1 : 2
            }
          </a>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography>Client secret:</Typography>
          <a
            className={"link_copy"}
            onClick={() => {
              navigator.clipboard.writeText(
                "uIy97Wr0eHo8j8uIPekuVpJoKLles1sntF7myth7"
              );
              setLinks({
                ...links,
                secret: true,
              });
            }}
          >
            {links.secret ? t("common:copied") : t("common:copy")}
          </a>
        </Box>

        <Box maxWidth={"500px"} marginTop={"10px"}>
          <img
            style={{ width: "100%", height: "auto" }}
            src={`${process.env.PUBLIC_URL}/shot.png`}
          />
        </Box>
      </Box>

      <Box marginTop={"30px"}>
        <ReactMarkdown
          source={mds.bitbucket}
          renderers={{
            link: (props) => {
              return (
                <Link href={props.href} className={styles.linkYellow}>
                  {props.node?.children?.[0]?.value}
                </Link>
              );
            },
          }}
        />
      </Box>

      <Box marginTop={"30px"}>
        <ReactMarkdown
          source={mds2.github}
          renderers={{
            link: (props) => {
              return (
                <Link href={props.href} className={styles.linkYellow}>
                  {props.node?.children?.[0]?.value}
                </Link>
              );
            },
          }}
        />
      </Box>
      {/*<Box marginTop={'20px'}>*/}
      {/*  <Typography variant={'h5'}>{t('common:api.videoInstruction')}</Typography>*/}
      {/*</Box>*/}
    </Box>
  );
};

export default ApiPage;
