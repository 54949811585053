import React, { FC, useEffect, useRef } from "react";
//@ts-ignore
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

type OktaSignWidgetProps = {
  config: any;
  onSuccess: (param: any) => void;
  onError: (param: any) => void;
};

const OktaSignWidget: FC<OktaSignWidgetProps> = ({
  config,
  onSuccess,
  onError,
}) => {
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect((): any => {
    if (!widgetRef.current) return false;

    const widget = new OktaSignIn(config);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef}></div>;
};

export default OktaSignWidget;
