import styled from "styled-components";

const VulnerabilityCounterWidget = styled.div<{ colorActive: string }>`
  border-radius: 5px;
  padding: 15px 10px;
  border: 1px solid
    ${(props) => (props.colorActive ? props.colorActive : "#EEEEEE")};
`;

export default VulnerabilityCounterWidget;
