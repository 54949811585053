import { FC } from "react";
import { Box, Typography, withStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import {
  CSRF,
  Directory,
  Info,
  Passwords,
  Service,
  Sql,
  TLS,
  Upload,
  WebApps,
  Xss,
} from "../icons/TableIcons";

const Paragraph = withStyles((theme: Theme) => ({
  root: {
    color: "rgba(0,0,0, .87)",
    lineHeight: "20px",
    fontSize: "14px",
  },
}))(Typography);

export const icons: {
  [key: string]: any;
} = {
  "weak-password": Passwords,
  "sql-injection": Sql,
  "xss-attack": Xss,
  information: Info,
  tls: TLS,
  directory: Directory,
  upload: Upload,
  csrf: CSRF,
  webapps: WebApps,
  quick: Service,
};

const iconsWeb: {
  [key: string]: any;
} = {
  bitrix: process.env.PUBLIC_URL + "/bitrix.png",
  gitlab: process.env.PUBLIC_URL + "/gitlab.jpeg",
  jira: process.env.PUBLIC_URL + "/jira.png",
  one_c: process.env.PUBLIC_URL + "/onec.jpeg",
};

const WithIcon: FC<any> = ({ data }) => {
  const Elem = icons?.[data?.plan];
  return (
    <Box
      style={{
        lineHeight: "1",
        padding: "12px 0",
        display: "flex",
        alignItems: "center",
      }}
    >
      {Elem ? (
        <Box minWidth={"62px"}>
          <Elem />
        </Box>
      ) : null}
      <Box marginLeft={"5px"}>
        <Paragraph>{data?.title ? data.title : data.name}</Paragraph>
      </Box>
    </Box>
  );
};

export const WithIconWeb: FC<any> = ({ row }) => {
  const str = iconsWeb?.[row?.original?.webapp];
  return (
    <Box
      style={{
        width: "100%",
        lineHeight: "1",
        padding: "12px 0",
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
      }}
    >
      {str ? (
        <Box
          minWidth={"90px"}
          maxWidth={row?.original?.webapp === "gitlab" ? "130px" : "110px"}
          width={"100%"}
          alignSelf={"center"}
        >
          <img style={{ width: "100%", height: "auto" }} src={str} />
        </Box>
      ) : null}
    </Box>
  );
};

export const WithIconMain: FC<any> = ({ row }) => {
  const Icon = icons?.[row?.original?.plan];
  return (
    <Box
      style={{
        lineHeight: "1",
        padding: "12px 0",
        display: "flex",
        alignItems: "center",
      }}
    >
      {Icon && (
        <Box minWidth={"62px"}>
          <Icon />
        </Box>
      )}
      <Box
        {...(Icon && {
          marginLeft: "5px",
        })}
      >
        <Paragraph>
          {row.original?.title ? row.original.title : row.original.name}
        </Paragraph>
      </Box>
    </Box>
  );
};
export default WithIcon;
