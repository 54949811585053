import { api, getRequest, postRequest } from "./request";
import { Schedule } from "types/common";
import { retrieveActiveUserToken } from "components/core/auth";
import { Lambda, LambdaCount } from "models/LambdaCount";
import { Asset, AssetRequest } from "models/Asset";
import Scan from "models/Scan";
import Service from "models/Service";
import { Vulnerability } from "models/Vulnerability";
import { Schedule as AssetSchedule } from "models";
import { isNull } from "lodash-es";

const API_URL = process.env.REACT_APP_API_URL;

export const apiUrl = (relativeUrl: string) => {
  return `${API_URL}/${relativeUrl}`;
};

export const profileLoadingMe = () => {
  return getRequest(apiUrl("me"));
};

export const getAboutMe = () => api.get("/me");

export const getMaxScans = () => api.get("/scans/limit/spent");

export const getAssets = () => api.get<Asset[]>("/assets");

export const getScans = () => api.get<Scan[]>("/scans");

export const getSingleAsset = (assetId: string) =>
  api.get<Asset>(`/assets/${assetId}`);

export const getSchedulesByAsset = (assetId: string) =>
  api.get<AssetSchedule[]>(`/assets/${assetId}/schedule`).then((response) => ({
    ...response,
    data: isNull(response.data) ? [] : response.data,
  }));

export const getPlansByAssetSchedule = (assetId: string, scheduleId: string) =>
  api
    .get<Lambda[]>(`/assets/${assetId}/schedule/${scheduleId}/plan`)
    .then((response) => ({
      ...response,
      data: isNull(response.data) ? [] : response.data,
    }));

export const postAsset = (assetRequest: AssetRequest) =>
  api.post("/assets", assetRequest);

export const getServicesByAssets = (assetID: number) =>
  api.get<Service[]>(`/assets/${assetID}/services`);

export const getServicesByScan = (scanID: number) =>
  api.get<Service[]>(`/scans/${scanID}/services`);

export const getVulnerabilitiesByScan = (scanID: number) =>
  api.get<Vulnerability[]>(`/scans/${scanID}/vulnerabilities`);

export const postScanRequest = async (params: any) => {
  return postRequest(apiUrl("scans"), params);
};

export const setSchedule = async (params: Schedule) => {
  return postRequest(apiUrl("schedule"), { ...params });
};

export const getVulnerabilities = () =>
  api.get<Vulnerability[]>("/vulnerabilities");

export const getVulnerabilitiesByService = (serviceID: string) =>
  api.get<Vulnerability[]>(`/services/${serviceID}/vulnerabilities`);

export const getVulnerabilitiesResolved = () =>
  api.get("/vulnerabilities/resolved");

export const getSingleVulnerability = (vulnerabilityID: number) =>
  api.get<Vulnerability>(`/vulnerabilities/${vulnerabilityID}`);

const pdfDecorate = async (res: any) => {
  const filename = res?.headers
    ?.get("Content-Disposition")
    ?.split("filename=")[1];
  const value = await res.blob();
  return {
    name: filename,
    value,
  };
};

export const getPdfScan = async (scanId: number) => {
  return await fetch(apiUrl(`pdf-generate/${scanId}`), {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${retrieveActiveUserToken()}`,
    },
  })
    .then(async (res) => {
      return pdfDecorate(res);
    })
    .then((value) => value)
    .catch((err) => console.log(err));
};

export const getPdfReport = async (scanId: number) => {
  return await fetch(apiUrl(`reports/${scanId}/pdf`), {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${retrieveActiveUserToken()}`,
    },
  })
    .then(async (res) => {
      return pdfDecorate(res);
    })
    .then((value) => value)
    .catch((err) => console.log(err));
};

export const getPdfSubpath = async (scanId: number) => {
  return await fetch(apiUrl(`subnets/scans/${scanId}/pdf`), {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${retrieveActiveUserToken()}`,
    },
  })
    .then(async (res) => {
      return pdfDecorate(res);
    })
    .then((value) => value)
    .catch((err) => console.log(err));
};

export const getLambdas = () =>
  api.get<LambdaCount[]>("/scans/latest/assets/lambda");
export const getPlans = () => api.get<any[]>("/plans");

export const postAssetLookup = (hostname: string, hint: string) =>
  api.post("/assets/lookup", { hostname, hint });

export const getLatestAssetPeriod = () =>
  api.get<any>("/scans/latest/assets/period");
export const getVulnerabilitiesStats = () =>
  api.get<any>("/vulnerabilities/stats");

export const sendContactUs = (data: any) =>
  api.post<any>("/contact/message", data);
