import { createReducer } from "utils/createReducers";
import {
  CLEAR_STATE_NOTIFICATION,
  SET_NOTIFICATION,
} from "../constants/notification";

const initState = {
  text: "",
  type: "warning",
  open: false,
};

export default createReducer(initState, {
  [SET_NOTIFICATION]: (state, { payload }: any) => ({
    ...state,
    ...payload,
  }),
  [CLEAR_STATE_NOTIFICATION]: (state) => ({
    ...state,
    ...initState,
  }),
});
