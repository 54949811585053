import { api } from "../../api/request";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  CHOOSE_ANALYTIC_ASSET,
  GET_ANALYTICS_BY_ASSETS,
  GET_ANALYTICS_BY_GUID,
  GET_ANALYTICS_BY_GUID_UNCHANGE,
  GET_ANALYTICS_BY_GUID_LOGS,
  GET_ANALYTIC_STATUS,
  GET_ANALYTIC_PORTS,
} from "../constants/analytics";
import { getAssets } from "../../api/api";
import { RootState } from "../index";

export const getAnalyticsHistory = createAsyncThunk<any, string>(
  GET_ANALYTICS_BY_GUID,
  async (guid: string) => {
    return await api.get(`/assets/${guid}/history`);
  }
);

export const getAnalyticsHistoryUnchange = createAsyncThunk<any, string>(
  GET_ANALYTICS_BY_GUID_UNCHANGE,
  async (guid: string) => {
    return await api.get(`/assets/${guid}/history/unchanged`);
  }
);

export const getAnalyticsHistoryLogs = createAsyncThunk<any, string>(
  GET_ANALYTICS_BY_GUID_LOGS,
  async (guid: string) => {
    return await api.get(`/assets/${guid}/history/log`);
  }
);

export const getAnalyticsAsset: any = createAsyncThunk<
  any,
  string,
  { state: RootState }
>(
  GET_ANALYTICS_BY_ASSETS,
  async () => {
    return await getAssets();
  },
  {
    condition(_, { getState }): boolean | undefined {
      const state = getState();
      if (state && state.analytics) {
        return;
      }
    },
  }
);

export const chooseAnalyticAsset = createAction(
  CHOOSE_ANALYTIC_ASSET,
  (payload: any) => {
    return {
      payload,
    };
  }
);

export const getAnalyticStatus = createAsyncThunk<any, string>(
  GET_ANALYTIC_STATUS,
  async (guid: string) => {
    return await api.get(`/assets/${guid}/history/status`);
  }
);

export const getAnalyticPorts = createAsyncThunk<any, string>(
  GET_ANALYTIC_PORTS,
  async (guid: string) => {
    return await api.get(`/assets/${guid}/history/ports`);
  }
);
