import Logo from "./assets/images/Logo.png";

export const oktaAuthConfig = {
  // Note: If your app is configured to use the Implicit flow
  // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
  // you will need to add `pkce: false`
  issuer: process.env.REACT_APP_URL_AUTH,
  // issuer: 'https://id.xsignal.io/oauth2/default',
  clientId: process.env.REACT_APP_CLIENT_ID,
  // clientId: '0oapoavokIRq1xMVw5d6',
  features: { registration: true },
  redirectUri: `${window.location.origin}/login/callback`,
};

export const oktaSignInConfig = {
  baseUrl: process.env.REACT_APP_URL_REGISTER,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  logo: Logo,
  features: { registration: true },
  i18n: {
    ru: {
      needhelp: "Не можете войти в систему?",
    },
  },
  authParams: {
    // If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    // pkce: false
  },
};
// 0oalt9lbxDpDfh5IA5d6
