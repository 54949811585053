export enum VulnerabilityType {
  CRITICAL = "critical",
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

export type Vulnerability = {
  type: VulnerabilityType;
  count: number;
};

export type VulnerabilitiesByScan = {
  index: string;
  assetName?: string;
  vulnerabilities: Vulnerability[];
};
