import { connect } from "react-redux";
import { RootState } from "store";

import { UserProfileLoading } from "components/core/UserProfileLoading";
import {
  loadSettings,
  loadSettingsFailed,
  loadSettingsSuccess,
} from "store/action/settings";

export default connect(
  (state: RootState) => ({
    ...state.settings,
  }),
  {
    loadSettings,
    loadSettingsSuccess,
    loadSettingsFailed,
  }
)(UserProfileLoading);
