import { applyMiddleware, combineReducers, createStore, Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import scans from "./reducers/scans";
import settings from "./reducers/settings";
import user from "./reducers/user";
import assets from "./reducers/assets";
import notification from "./reducers/notification";
import vulnerabilities from "./reducers/vulnerabilities";
import subpaths from "./reducers/subpaths";
import reports from "./reducers/reports";
import history from "./reducers/history";
import scheduledScans from "./reducers/scheduled_scans";
import { analyticsReducer } from "./reducers/analytics";
import { scanListReducer } from "./reducers/scanList";

const rootReducer = combineReducers({
  scans,
  settings,
  user,
  notification,
  assets,
  vulnerabilities,
  subpaths,
  reports,
  history,
  scheduledScans,
  analytics: analyticsReducer,
  scanList: scanListReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  any,
  Action<string>
>;
export type AppThunkDispatch = ThunkDispatch<RootState, void, Action<string>>;

export default createStore(
  rootReducer,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk)
);
