import { ActionCreator } from "redux";
import {
  CLEAR_STATE_NOTIFICATION,
  SET_NOTIFICATION,
} from "../constants/notification";
import { AppThunk } from "../index";

export const setNotification: ActionCreator<any> =
  (payload: any): AppThunk =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_NOTIFICATION,
      payload,
    });
  };

export const clearNotification: ActionCreator<any> =
  (): AppThunk => async (dispatch) => {
    dispatch({
      type: CLEAR_STATE_NOTIFICATION,
    });
  };
