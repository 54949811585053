export const GET_SUBPATHS = "/subpaths/getSubpaths";
export const GET_SUBPATHS_SCANS = "/subpaths/getSubpaths/scans";
export const CHOOSE_SUBPATHS = "/subpaths/subpath/choose";
export const GET_SUBPATHS_FUNC = "/subpaths/catalog/get";
export const GET_SUBPATHS_RADIO = "/subpaths/catalog/getRadio";
export const SET_SUBPATH_TYPE = "/subpaths/set/type";
export const SET_SUBPATH_ALLFUNC_TYPE = "/subpaths/get/all_func";
export const SET_SUBPATH_CONFIG_TYPE_CHECK = "/subpaths/set/config/type/check";
export const SET_SUBPATH_CONFIG_TYPE = "/subpaths/set/config/type";
export const START_SUBPATH_SCAN = "/subpaths/start/scan";
export const SET_ACTIVE_SUBPATH_SCAN = "/subpaths/set/active/subpath";
export const REMOVE_CURRENT_SUBPATH_SCAN = "/subpaths/remove/active/subpath";
export const GET_MAX_HOSTS_SUBNETS = "/subpaths/get/subpath/max";
export const SET_SUBNETS_SERVICE_ACTIVE =
  "/subpaths/set/subpath/active/service";
export const GET_SUBNETS_SERVICE_DATA = "/subpaths/get/subpath/services";
export const GET_SUBNETS_SERVICE_DATA_DETAILS =
  "/subpaths/get/subpath/services/details";
export const GET_SUBNETS_SERVICE_DATA_VULNERABILITIES =
  "/subpaths/get/subpath/vulnerabilites/details";
export const GET_SUBNETS_VULNERABILITY_ID =
  "/subpaths/get/subpath/vulnerabilites/byId";
