import styled from "styled-components";

const BoxContent = styled.div`
  background-color: #ffffff;
  color: rgb(103, 106, 108);
  padding: 15px 20px 20px 15px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid;
  border-width: 1px;
`;

export default BoxContent;
