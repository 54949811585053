import { AppThunk } from "../index";
import { setNotification } from "./notification";
import { getAssets, postAsset, postAssetLookup } from "api/api";
import { AssetPlan } from "models/Asset";
import { api } from "../../api/request";
import i18n from "i18next";
import { ActionCreator } from "redux";
import {
  GET_ASSETS,
  GET_ASSETS_ARCHIVE,
  GET_ASSETS_MAIN_LOADING,
  SET_ASSETS_MAXIMUM,
  SET_ASSETS_VERIFIED,
  SET_TOKENS_ASSETS,
} from "../constants/assets";
// import {parseFromUrl} from "@okta/okta-auth-js";

export const postAssetThunk =
  (hostname: string, alias?: string): AppThunk<Promise<any>> =>
  async (dispath: any) => {
    try {
      if (hostname === "") {
        dispath(
          setNotification({
            text: i18n.t("pageAssets:emptyHostname"),
            type: "error",
            open: true,
          })
        );
      }
      const regexp =
        /^(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?(?:(?:[-\w]+\.)+(?:com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|travel|[a-z]{2}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)?$/gm;
      const ipRegexp =
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      // let finded = hostname.match(/([^\/\/][^wwwg.]([^/?#]*))/gm);
      let finded = hostname.match(/([^\/\/][^www.]([^/?#]*))/gm);

      let ipAddress;
      let hostnameToPost = hostname;
      if (true) {
        if (hostname.match(ipRegexp)) {
          ipAddress = hostname;
          finded = [ipAddress];
        }
        if (!hostname.match(ipRegexp) && finded?.length) {
          try {
            const stringRequest = finded?.[1]
              ? finded?.[1]?.match(/(\.)?(.*)/)?.[2]
              : finded?.[0]?.match(/(\.)?(.*)/)?.[0];
            const ipHost = await postAssetLookup(stringRequest as string, hostname);
            const adress = ipHost.data.address as string[];
            hostnameToPost = ipHost.data.hostname as string;
            if (Boolean(adress.length)) {
              ipAddress = adress?.[0];
            } else {
              dispath(
                setNotification({
                  text: i18n.t("pageAssets:decodeAddressError"),
                  type: "error",
                  open: true,
                })
              );
            }
          } catch (error) {
            dispath(
              setNotification({
                text: i18n.t("pageAssets:notFoundIP"),
                type: "error",
                open: true,
              })
            );
            return false;
          }
        }
        if (ipAddress) {
          const res = await postAsset({
            hostname: hostnameToPost,
            // hostname: finded?.[1]
            //   ? finded?.[1]?.match(/(\.)?(.*)/)?.[2]
            //   : finded?.[0]?.match(/(\.)?(.*)/)?.[0],
            ip: ipAddress.trim(),
            plan: AssetPlan.Quick,
            aName: alias,
          });
          if (res.status === 200) {
            dispath(
              setNotification({
                text: i18n.t("pageAssets:assetCreated"),
                type: "success",
                open: true,
              })
            );
            return res.data;
          }
        }
      }
      // else {
      //   dispath(
      //     setNotification({
      //       text: i18n.t('pageAssets:incorrectHostname'),
      //       type: 'error',
      //       open: true
      //     })
      //   )
      // }
    } catch (error) {
      dispath(
        setNotification({
          text: error?.response?.data?.data?.["request.IP"]
            ? error?.response?.data?.data?.["request.IP"]
            : error?.message,
          type: "error",
          open: true,
        })
      );
      throw new Error(
        error?.response?.data?.data?.["request.IP"]
          ? error?.response?.data?.data?.["request.IP"]
          : error?.message
      );
    }
  };

export const editAssetThunk =
  (aName: string, assetId: number): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const response = await api.put("/assets", {
        aName,
        assetId,
      });
      if (response.status === 200) {
        dispatch(
          setNotification({
            text: i18n.t("pageAssets:notificationEditSuccess"),
            type: "success",
            open: true,
          })
        );
        return true;
      }
      return false;
    } catch (e) {
      setNotification({
        text: i18n.t("pageAssets:notificationEditError"),
        type: "success",
        open: true,
      });
      console.error(e);
      return false;
    }
  };

export const archiveThunk =
  (assetId: number): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const resp = await api.post(`/assets/${assetId}/archive`);
      if (resp.status === 200) {
        dispatch(
          setNotification({
            text: i18n.t("pageAssets:notification"),
            type: "success",
            open: true,
          })
        );
        return true;
      }
      return false;
    } catch (error) {
      dispatch(
        setNotification({
          text: i18n.t("pageAssets:notificationError"),
          type: "error",
          open: true,
        })
      );
      return false;
    }
  };

export const unArchiveThunk =
  (assetId: number): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const resp = await api.delete(`/assets/${assetId}/archive`);
      if (resp.status === 200) {
        dispatch(
          setNotification({
            text: i18n.t("pageAssets:notificationUnarchive"),
            type: "success",
            open: true,
          })
        );
        return true;
      }
      return false;
    } catch (error) {
      dispatch(
        setNotification({
          text: i18n.t("pageAssets:notificationUnarchiveError"),
          type: "error",
          open: true,
        })
      );
      return false;
    }
  };

export const getAssetsThunk: ActionCreator<any> =
  (): AppThunk => async (dispatch) => {
    try {
      dispatch({
        type: GET_ASSETS_MAIN_LOADING,
        payload: true,
      });
      const resp = await getAssets();
      if (resp.data) {
        if (resp.data.length) {
          dispatch({
            type: GET_ASSETS,
            payload: resp.data,
          });
        }
      }
      dispatch({
        type: GET_ASSETS_MAIN_LOADING,
        payload: false,
      });
    } catch (e) {
      dispatch({
        type: GET_ASSETS_MAIN_LOADING,
        payload: false,
      });
      dispatch(
        setNotification({ text: e?.message, type: "error", open: true })
      );

      // throw new Error(e)
    }
  };

export const getArchiveAssets: ActionCreator<any> =
  (): AppThunk => async (dispatch) => {
    try {
      const resp = await getAssets();
      if (resp.data) {
        if (resp.data.length) {
          const filtered = resp.data.filter((item) => item.archive);
          dispatch({
            type: GET_ASSETS_ARCHIVE,
            payload: filtered,
          });
        }
        dispatch({
          type: SET_ASSETS_MAXIMUM,
          // payload: resp?.data?.length >= SUBSCRIPTIONS?.[getState().settings.subscriptionPlan]?.rules?.maxHosts
        });
      }
    } catch (e) {
      throw new Error(e);
    }
  };

export const verifyTokenThunk: ActionCreator<any> =
  (assetId: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (getState().assets.tokensAssets[`${assetId}`]) return;
      const resp = await api.post("/assets/verify/token", {
        assetId,
      });
      if (resp.data) {
        dispatch({
          type: SET_TOKENS_ASSETS,
          payload: {
            field: assetId,
            value: resp.data,
          },
        });
      }
    } catch (e) {
      throw new Error(e);
    }
  };

export const verificationFileDownload: ActionCreator<any> =
  (assetId: number, name: string): AppThunk =>
  async () => {
    try {
      const resp = await api.get(`/assets/${assetId}/verification/file`);
      if (resp.data) {
        if (resp && resp.data) {
          // @ts-ignore
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // @ts-ignore
            `${name}`
          );
          document.body.appendChild(link);
          link.click();
          // @ts-ignore
          link?.parentNode.removeChild(link);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

export const verifyAssets =
  (assetId: number): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const resp = await api.post("/assets/verify", {
        assetId,
      });
      if (resp.data) {
        dispatch({
          type: SET_ASSETS_VERIFIED,
          payload: {
            field: assetId,
            value: resp.data.status,
          },
        });
        return true;
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

export const deleteAssets =
  (id: number): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const resp = await api.delete(`/assets/${id}`);
      if (resp.status === 200) {
        dispatch(
          setNotification({
            text: i18n.t("pageAssets:deleteSuccessNotificationAsset"),
            type: "success",
            open: true,
          })
        );
        return true;
      }
      return false;
    } catch (e) {
      dispatch(
        setNotification({
          text: i18n.t("pageAssets:deleteErrorNotificationAsset"),
          type: "error",
          open: true,
        })
      );
      return false;
    }
  };

// /assets/verify
// /assets/{assetID}/verification/file
