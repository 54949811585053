export const SCANS_CHECKED = "scans/checked/scan";
export const SCHEDULED_CHECKED = "scans/checked/schedule";
export const CONFIG_CHECKED = "scans/checked/config";
export const SET_SCAN_TYPE = "scans/checked/scantype";
export const CLEAR_SCHEDULE = "scans/checked/schedule/clear";
export const GET_CATALOG_FUNC = "scans/checked/catalog/functions";
export const GET_RADIO_BUTTONS = "scans/checked/catalog/radio-buttons";
export const GET_CATALOG_ALLFUNC = "scans/checked/catalog/all_function";
export const SET_SCAN_TYPE_CHECK = "scans/checked/scantype/check";
export const SET_SCAN_CONFIG_CHECK = "scans/checked/config/check";
export const SET_SCAN_START_TIME = "scans/checked/scan/start/time";
export const SET_SCAN_ACTIVE = "scans/checked/scan/set/active";
export const REMOVE_SCAN_ACTIVE = "scans/checked/scan/remove/active";
export const CATALOG_SCAN_WEBAPP = "scans/catalog/webapp/get";
export const CHOOSE_WEB_CONFIG_TYPE = "scans/choose/webapp/config";
export const CHOOSE_WEB_CONFIG_SUBTYPE = "scans/choose/webapp/config/subtype";
