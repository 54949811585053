import { FC } from "react";
import { Box } from "@material-ui/core";

const MainLoader: FC = () => {
  return (
    <Box className={"image-layout"}>
      <Box className={"image-container"}>
        <img src={`${process.env.PUBLIC_URL}/Logo.png`} />
      </Box>
      <Box className={"spinner-container"}>
        <div className="spinner">
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
        </div>
      </Box>
    </Box>
  );
};

export default MainLoader;
