import { createContext } from "react";
import UserProfile from "./models/UserProfile";

export interface UserContextData {
  activeUserProfile?: UserProfile;
  setActiveUserProfile: (profile: UserProfile) => void;
  clearActiveUserProfile: () => void;
}

const UserContext = createContext<UserContextData>({
  activeUserProfile: undefined,
  setActiveUserProfile: (_: UserProfile) => {
    return null;
  },
  clearActiveUserProfile: () => {
    return null;
  },
});
export default UserContext;
