const sizes = {
  mobileL: "500px",
  tablet: "960px",
  desktop: "2570px",
};

export const devices = {
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(max-width: ${sizes.desktop})`,
};
