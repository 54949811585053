import { createReducer } from "../../utils/createReducers";
import { SettingsActionTypes, SubscriptionType } from "../constants/settings";

const initialState = {
  subscriptionPlan: SubscriptionType.Free,
  status: "idle",
  error: null,
  email: null,
  tour: false,
  img: null,
  name: null,
  lang: null,
  requestLoadStatus: false,
  limitSpent: 0,
  userData: {},
  groups: [],
};

export default createReducer(initialState, {
  [SettingsActionTypes.Load]: (state) => ({
    ...state,
    status: "loading",
    error: null,
  }),
  [SettingsActionTypes.LoadSuccess]: (state, { payload }: any) => ({
    ...state,
    ...payload,
    status: "succeeded",
    error: null,
  }),
  [SettingsActionTypes.LoadFailed]: (state, { payload }: any) => ({
    ...state,
    status: "failed",
    error: payload,
  }),
  [SettingsActionTypes.LoadRequestStatus]: (state, { payload }: any) => ({
    ...state,
    requestLoadStatus: payload,
  }),
  [SettingsActionTypes.LoadUserInfo]: (state, { payload }: any) => ({
    ...state,
    userData: payload,
  }),
});
