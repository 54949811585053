export enum AssetPlan {
  Full = "full",
  Quick = "quick",
}

interface AssetRequestBasic {
  plan: AssetPlan;
}

interface AssetRequestWithHostname extends AssetRequestBasic {
  hostname: string;
  aName?: string;
}

interface AssetRequestWithIp extends AssetRequestBasic {
  ip: string;
}

export type AssetRequest =
  | AssetRequestWithIp
  | AssetRequestWithHostname
  | (AssetRequestWithIp & AssetRequestWithHostname);

export interface Asset {
  id: number;
  hostname: string;
  ip: string;
  servicesCount: number;
  vulnerabilitiesCount: number;
  scanStatus: string;
  regex: string;
  lastScanTime?: string;
  archive: boolean;
}
