import { createReducer } from "utils/createReducers";
import * as constants from "../constants/scans";

type stateProps = {
  choosenScan: {
    // choosen for scan asset
    scanAssets: any;
    // scanConfig: any[]
    scanSchedule: any;
    scanType: any;
    checkedScanType: any;
    checkedConfigScan: any[];
    checkedWebConfig: any[];
    webConfigSubtype: string[];
  };
  scanConfigs: any;
  catalog: any;
  catalogRadio: any;
  firstCatalogRadio: any;
  startTime: any;
  activeScan: any;
  currentScan: any;
  catalogWebApps: any[];
};

const initialState: stateProps = {
  choosenScan: {
    webConfigSubtype: [],
    // scan assets - choosen asset
    scanAssets: {},
    // scanConfig: [{
    //   name: 'xsignal-ports',
    //   id: 'xsignal-ports'
    // }],
    scanType: {
      label: "Quick scan",
      value: "quick",
    },
    scanSchedule: {},
    checkedScanType: {},
    checkedConfigScan: [],
    checkedWebConfig: [],
  },
  catalog: {
    scanGroups: [],
    allFunc: [],
  },
  scanConfigs: {
    data: [
      {
        name: "xsignal-csrf",
        id: "xsignal-csrf",
      },
      {
        name: "xsignal-file-upload",
        id: "xsignal-file-upload",
      },
      {
        name: "xsignal-ftp-anon",
        id: "xsignal-ftp-anon",
      },
      {
        name: "xsignal-hostmap-crtsh",
        id: "xsignal-hostmap-crtsh",
      },
      {
        name: "xsignal-http-enum-dir",
        id: "xsignal-http-enum-dir",
      },
      {
        name: "xsignal-mysql-empty-password",
        id: "xsignal-mysql-empty-password",
      },
      {
        name: "xsignal-path-traversal",
        id: "xsignal-path-traversal",
      },
      {
        name: "xsignal-ports",
        id: "xsignal-ports",
      },
      {
        name: "xsignal-security-headers",
        id: "xsignal-security-headers",
      },
      {
        name: "xsignal-sql-injection",
        id: "xsignal-sql-injection",
      },
      {
        name: "xsignal-ssh-brute",
        id: "xsignal-ssh-brute",
      },
      {
        name: "xsignal-ssl-cert-info",
        id: "xsignal-ssl-cert-info",
      },
      {
        name: "xsignal-sslv2",
        id: "xsignal-sslv2",
      },
      {
        name: "xsignal-ssl-ver-rating",
        id: "xsignal-ssl-ver-rating",
      },
      {
        name: "xsignal-vulnerabilities",
        id: "xsignal-vulnerabilities",
      },
      {
        name: "xsignal-xss",
        id: "xsignal-xss",
      },
      {
        name: "xsignal-ssl-vulnerabilities",
        id: "xsignal-ssl-vulnerabilities",
      },
    ],
  },
  catalogRadio: {
    custom: {
      label: "Custom scan",
      value: "custom",
    },
  },
  firstCatalogRadio: {
    custom: {
      label: "Custom scan",
      value: "custom",
    },
  },
  startTime: null,
  activeScan: {},
  currentScan: null,
  catalogWebApps: [],
};

export default createReducer(initialState, {
  [constants.SCANS_CHECKED]: (state, { payload }: any) => ({
    ...state,
    choosenScan: {
      ...state.choosenScan,
      scanAssets: payload,
    },
  }),
  // [constants.CONFIG_CHECKED]: (state, { payload }: any) => ({
  //   ...state,
  //   choosenScan: {
  //     ...state.choosenScan,
  //     scanConfig: payload.status ?
  //       Array.isArray(payload.item) ?
  //         payload.item :
  //         [...state.choosenScan.scanConfig, payload.item] :
  //       state.choosenScan.scanConfig.filter((item) => (
  //         item.id !== payload.item.id
  //       ))
  //   }
  // }),
  [constants.SCHEDULED_CHECKED]: (state, { payload }: any) => ({
    ...state,
    choosenScan: {
      ...state.choosenScan,
      scanSchedule: {
        ...payload,
      },
    },
  }),
  [constants.SET_SCAN_TYPE]: (state, { payload }: any) => ({
    ...state,
    choosenScan: {
      ...state.choosenScan,
      scanType: payload,
    },
  }),
  [constants.CLEAR_SCHEDULE]: (state) => ({
    ...state,
    choosenScan: {
      ...state.choosenScan,
      scanSchedule: {},
    },
  }),
  [constants.GET_CATALOG_FUNC]: (state, { payload }: any) => ({
    ...state,
    catalog: {
      ...state.catalog,
      scanGroups: payload,
    },
  }),
  [constants.GET_CATALOG_ALLFUNC]: (state, { payload }: any) => ({
    ...state,
    catalog: {
      ...state.catalog,
      allFunc: payload,
    },
  }),
  [constants.GET_RADIO_BUTTONS]: (state, { payload }: any) => ({
    ...state,
    catalogRadio: {
      ...state.catalogRadio,
      ...payload,
    },
    firstCatalogRadio: {
      ...state.firstCatalogRadio,
      ...payload,
    },
  }),
  [constants.SET_SCAN_TYPE_CHECK]: (state, { payload }: any) => ({
    ...state,
    choosenScan: {
      ...state.choosenScan,
      checkedScanType: payload,
    },
  }),
  [constants.SET_SCAN_CONFIG_CHECK]: (state, { payload }: any) => ({
    ...state,
    choosenScan: {
      ...state.choosenScan,
      checkedConfigScan: payload,
    },
  }),
  [constants.SET_SCAN_START_TIME]: (state, { payload }: any) => ({
    ...state,
    startTime: payload,
  }),
  [constants.SET_SCAN_ACTIVE]: (state, { payload }: any) => ({
    ...state,
    activeScan: {
      ...state.activeScan,
      [payload]: true,
    },
    currentScan: payload,
  }),
  [constants.REMOVE_SCAN_ACTIVE]: (state, { payload }: any) => ({
    ...state,
    activeScan: {
      ...state.activeScan,
      [payload]: false,
    },
    currentScan: null,
  }),
  [constants.CATALOG_SCAN_WEBAPP]: (state, { payload }: any) => ({
    ...state,
    catalogWebApps: payload,
  }),
  [constants.CHOOSE_WEB_CONFIG_TYPE]: (state, { payload }: any) => ({
    ...state,
    choosenScan: {
      ...state.choosenScan,
      checkedWebConfig: payload,
    },
  }),
  [constants.CHOOSE_WEB_CONFIG_SUBTYPE]: (state, { payload }: any) => ({
    ...state,
    choosenScan: {
      ...state.choosenScan,
      webConfigSubtype: payload,
    },
  }),
});
