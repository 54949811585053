import React, { FC, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import example from "../../../mdFiles/Example.md";
import subnetExample from "../../../mdFiles/SubnetRange.md";
import { makeStyles } from "@material-ui/core/styles";
import {
  BreadcrumbsLayout,
  BreadcrumbsLayoutUnit,
} from "../../comps/Layout/BreadcrumbsLayout";
import { useTranslation } from "react-i18next";
import { HeaderContext } from "../../../context";

const useStyles = makeStyles({
  container: {
    padding: "20px",
    backgroundColor: "#fff",
  },
  title: {
    color: "rgba(0, 0, 0, .87)",
    fontSize: "20px",
    fontWeight: 500,
  },
});

const mdExample: {
  [key: string]: any;
} = {
  subnetWhat: example,
  subnetRange: subnetExample,
};

const InfoDescription: FC<RouteComponentProps<any, any, any>> = ({
  match,
  location,
}) => {
  const headerContext = useContext(HeaderContext);
  const [t] = useTranslation(["info"]);
  const styles = useStyles();
  const [markdown, setMarkdown] = useState<any>(null);

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsLayoutUnit[]>([
    {
      title: t("info:title"),
      path: "/info",
    },
  ]);

  useEffect(() => {
    if (match.params?.id in mdExample) {
      fetch(mdExample[match.params?.id])
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          setMarkdown(text);
        });
    }
  }, []);

  useEffect(() => {
    headerContext.setHeader(location?.state?.headTitle);
    return () => {
      headerContext.setHeader("");
    };
  }, [location.state?.headTitle]);

  useEffect(() => {
    if (breadcrumbs?.length === 1) {
      setBreadcrumbs([
        ...breadcrumbs,
        {
          title: location.state?.title,
        },
      ]);
    }
  }, [match.params.id]);

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>{location.state?.title}</Typography>

      <Box marginY={"10px"}>
        <BreadcrumbsLayout units={breadcrumbs} />
      </Box>
      <ReactMarkdown source={markdown} />
    </Box>
  );
};

export default InfoDescription;
