import React from "react";
import ReactDOM from "react-dom";
import App from "./components/core/App";
import reportWebVitals from "./reportWebVitals";
import "normalize.css";
import "./default.css";
import { Security } from "@okta/okta-react";
import { oktaAuthConfig } from "./config";
import { OktaAuth } from "@okta/okta-auth-js";
// import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "theme";
import { Provider } from "react-redux";
import store from "./store";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import "./i18n";
import { Condition } from "./components/comps/Condition";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import keycloak from "./utils/keycloak";
import { storeActiveUserToken } from "./components/core/auth";
import { CssBaseline } from "@material-ui/core";

const oktaAuth = new OktaAuth(oktaAuthConfig);

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Condition match={process.env.REACT_APP_SECURITY === "okta"}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={() => {}}
        restoreOriginalUri={() => {}}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </Security>
    </Condition>

    <Condition match={process.env.REACT_APP_SECURITY === "keycloak"}>
      <ReactKeycloakProvider
        authClient={keycloak}
        onTokens={({ idToken, refreshToken, token }) => {
          if (token) {
            storeActiveUserToken(token as string, refreshToken, idToken);
          }
        }}
        initOptions={{
          onLoad: "login-required",
        }}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </ReactKeycloakProvider>
    </Condition>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
