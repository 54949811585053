import { createReducer } from "utils/createReducers";
import { GET_REPORTS } from "../constants/reports";

const initState = {
  data: [],
};

export default createReducer(initState, {
  [GET_REPORTS]: (state, { payload }: any) => ({
    ...state,
    data: payload,
  }),
});
