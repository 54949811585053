import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";

import { VulnerabilitySeverity } from "models/VulnerabilitySeverity";
import Tag, { TagCount } from "./Tag";
import { useTranslation } from "react-i18next";

const severityStyle = makeStyles((theme) =>
  createStyles({
    unknown: {
      background: theme.palette.grey.A400,
    },
    critical: {
      background: theme.palette.critical.main,
    },
    high: {
      background: theme.palette.high.main,
    },
    medium: {
      background: theme.palette.medium.main,
    },
    low: {
      background: theme.palette.low.main,
    },
    Неизвестный: {
      background: theme.palette.grey.A400,
    },
    Критический: {
      background: theme.palette.critical.main,
    },
    Высокий: {
      background: theme.palette.high.main,
    },
    Средний: {
      background: theme.palette.medium.main,
    },
    Низкий: {
      background: theme.palette.low.main,
    },
  })
);

export const getVulnerabilitiesSeverityClass = (
  severity: VulnerabilitySeverity
): string => {
  return severityStyle()[severity];
};

const VulnerabilityTag = ({
  severity,
}: {
  severity: VulnerabilitySeverity | string;
}) => {
  const [t] = useTranslation();
  return (
    <Tag
      className={getVulnerabilitiesSeverityClass(
        severity as VulnerabilitySeverity
      )}
    >
      {t(`lvl.${severity}`)}
    </Tag>
  );
};
// TODO: remove after change all tables
export const VulnerabilityTagTable = (props: any) => {
  const [t] = useTranslation();
  return (
    <Tag className={getVulnerabilitiesSeverityClass(props.value)}>
      {t(`lvl.${props.value}`)}
    </Tag>
  );
};

export const VulnerabilityTagTableMain = ({ value }: any) => {
  const [t] = useTranslation();
  return (
    <Tag className={getVulnerabilitiesSeverityClass(value)}>
      {t(`lvl.${value}`)}
    </Tag>
  );
};

export const VulnerabilityTagWithCount = ({
  severity,
  count,
}: {
  severity: VulnerabilitySeverity | string;
  count: number;
}) => {
  const [t] = useTranslation();
  return (
    <TagCount
      className={getVulnerabilitiesSeverityClass(
        severity as VulnerabilitySeverity
      )}
    >
      {t(`lvl.${severity?.toLowerCase()}`)} {count}
    </TagCount>
  );
};

export default VulnerabilityTag;
