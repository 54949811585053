import { FC, lazy, Suspense, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import { oktaSignInConfig } from "../../config";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import UserContext from "../../UserContext";
import { retrieveActiveUserToken } from "./auth";
import UserProfile from "../../models/UserProfile";
import NotFound from "../pages/NotFound";
import ConfirmEmail from "../pages/ConfirmEmail";
import LoginOkta from "../pages/LoginOkta";
import { DrawerComponent } from "../comps/Drawer";
import { HeaderBar } from "components/comps/header";

import UserProfileLoading from "containers/UserProfileLoading";
import Dashboard from "containers/Dashboard";
import { setUser } from "store/action/user";
import { connect, useDispatch } from "react-redux";
import { InfoProps } from "store/reducers/user";
import ErrorMainBoundary from "../Boundary/ErrorMainBoundary";
import { HeaderContext, ToastContext } from "../../context";
import Toast, { Severity } from "../comps/Toast";
import { RootState } from "store";
import { clearNotification } from "store/action/notification";
import MainLoader from "../comps/Loader/MainLoader";
import { loadSettingsSuccess } from "store/action/settings";
import { useTranslation } from "react-i18next";
import InfoMain from "../pages/Info";
import ApiPage from "../../containers/ApiPage";
import { devices } from "../../theme/styled-devices";
import KeycloakHOC from "../comps/Keycloak/KeycloakHOC";
import { updateUser } from "../../api/users";
import i18next from "i18next";
import { getAboutMe } from "../../api/api";
import { Onboarding } from "components/comps/Onboarding";

const AssetsList = lazy(() => import("../../containers/AssetsList"));
const VulnerabilitiesList = lazy(() => import("../pages/VulnerabilitiesList"));
const VulnerabilitiesById = lazy(
  () => import("../../containers/VulnerabilitiesById")
);
const VulnerabilityView = lazy(() => import("../pages/VulnerabilityView"));
const VulnerabilitySubnetView = lazy(
  () => import("containers/VulnerabilitySubnetView")
);
const ServicesList = lazy(() => import("../pages/AssetsServicesList"));
const ScansList = lazy(() => import("containers/ScansList"));
const ScanServicesList = lazy(() => import("containers/ScanServicesList"));
const SubnetsServicesPage = lazy(
  () => import("components/pages/Scans/SubnetsServicesPage")
);
const SubnetsServicesDetails = lazy(
  () => import("containers/SubnetsServicesDetails")
);
const SubnetsServicesVulnerabilities = lazy(
  () => import("containers/SubnetsServicesVulnerabilities")
);
const SettingsView = lazy(() => import("containers/SettingsView"));
const ReferenceData = lazy(() => import("../pages/ReferenceData"));
const AddNewScan = lazy(() => import("containers/AddNewScan"));
const AddNewScanSubnet = lazy(() => import("containers/AddNewScanSubnet"));
const Schedules = lazy(() => import("../pages/Schedules/Schedules"));
const ArchiveAssets = lazy(() => import("containers/ArchiveAssets"));
const WelcomePage = lazy(() => import("../pages/WelcomePage"));
const SubnetPage = lazy(() => import("containers/SubnetPage"));
const ReportsPage = lazy(() => import("containers/ReportsPage"));
const HistoryPage = lazy(() => import("containers/HistoryPage"));
const HistoryPageItem = lazy(() => import("containers/HistoryPageItem"));
const ScheduledScansPage = lazy(() => import("containers/ScheduledScansPage"));
// const PentestersPage = lazy(() => import("containers/PentesterPage"))
const AnalyticHistoryPage = lazy(
  () => import("containers/AnalyticHistory/AnalyticHistoryPage")
);
const AnalyticHistoryPageMain = lazy(
  () => import("containers/AnalyticHistory/AnalyticHistoryPageMain")
);
const ContactUsPage = lazy(() => import("../pages/ContactUs"));

const AppLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.div<{ opened: boolean }>`
  display: flex;
  margin-top: 64px;
  margin-left: ${(props) => (props.opened ? "250px" : "40px")};
  background-color: #e5e5e5;
  @media ${devices.tablet} {
    margin-left: 0px;
  }
`;

const ContentContainer = styled.div`
  flex: 1 1;
  width: 100%;
  padding: 0 21px 21px;
  @media ${devices.mobileL} {
    padding: 0 10px;
  }
`;

const customHistory = createBrowserHistory();

const AppInner = ({
  pintester = false,
  initialized,
}: {
  email: string | null;
  pintester?: boolean;
  initialized?: boolean;
  keycloak?: any;
}) => {
  const [successAuth, setSuccessAuth] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const userContext = useContext(UserContext);

  useEffect(() => {
    if (process.env.REACT_APP_SECURITY === "keycloak") {
      if (initialized) {
        (async function () {
          const resp = await updateUser({
            lang: i18next.language,
          });
          if (resp.status === 200) {
            const response = await getAboutMe();
            if (response?.data) {
              dispatch(
                loadSettingsSuccess({
                  ...response?.data,
                  subscription: "basic",
                })
              );
              if (response?.data?.redirectToStripe) {
                window.location.replace(`${process.env.REACT_APP_URL_BILLING}`);
                return;
              }
              userContext.setActiveUserProfile({
                jwt: "",
                name: response?.data?.name,
                email: response?.data?.email,
              });
              setSuccessAuth(true);
            }
          }
        })();
      }
    }
  }, [initialized]);

  if (!userContext.activeUserProfile) {
    const storedAuthToken = retrieveActiveUserToken();
    if (process.env.REACT_APP_SECURITY === "okta") {
      if (!storedAuthToken) {
        return (
          <AppLayout>
            <HeaderBar
              opened={false}
              open={open}
              setOpen={(val: boolean) => setOpen(val)}
            />
            <Body opened={false}>
              <ContentContainer
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Router history={customHistory}>
                  <ErrorMainBoundary>
                    <Switch>
                      <Redirect from="/login" to="/" />
                      <Route
                        path="/"
                        exact
                        component={() => (
                          <LoginOkta config={oktaSignInConfig} />
                        )}
                      />
                      <Route path="/login/callback" component={LoginCallback} />
                      <Route
                        path="/confirm-email"
                        exact
                        component={ConfirmEmail}
                      />
                      <Route component={NotFound} />
                    </Switch>
                  </ErrorMainBoundary>
                </Router>
              </ContentContainer>
            </Body>
          </AppLayout>
        );
      } else {
        return <UserProfileLoading />;
      }
    }
  }

  return (
    <>
      <Router history={customHistory}>
        <Onboarding>
          <Suspense fallback={MainLoader}>
            <ErrorMainBoundary>
              {(process.env.REACT_APP_SECURITY === "keycloak" && successAuth) ||
              process.env.REACT_APP_SECURITY === "okta" ? (
                <AppLayout>
                  <HeaderBar
                    opened={true}
                    open={open}
                    setOpen={(val: boolean) => setOpen(val)}
                    withUser
                  />

                  <DrawerComponent
                    setClose={() => setOpen(false)}
                    setOpen={(val: boolean) => setOpen(val)}
                    open={open}
                    opened={true}
                    pintester={pintester}
                  />

                  <Body opened={true}>
                    <ContentContainer>
                      <Switch>
                        <Redirect from="/login" to="/" />
                        <Redirect from="/register" to="/" />

                        <Route path="/" exact component={Dashboard} />
                        <Route
                          path="/settings"
                          exact
                          component={SettingsView}
                        />
                        <Route path="/assets" exact component={AssetsList} />
                        <Route
                          path="/assets/archive"
                          exact
                          component={ArchiveAssets}
                        />
                        <Route
                          exact
                          path="/assets/:id/services"
                          component={ServicesList}
                        />
                        <Route
                          exact
                          path="/assets/:id/schedules"
                          component={Schedules}
                        />
                        <Route
                          path="/vulnerabilities"
                          exact
                          component={VulnerabilitiesList}
                        />
                        <Route
                          path="/services/:id/vulnerabilities"
                          exact
                          component={VulnerabilitiesById}
                        />
                        <Route
                          path="/scans/:id/services"
                          exact
                          component={ScanServicesList}
                        />
                        <Route
                          path="/scans/:id/services/subnets"
                          exact
                          component={SubnetsServicesPage}
                        />
                        <Route
                          path="/scans/:id/services/subnets/:detailId"
                          exact
                          component={SubnetsServicesDetails}
                        />
                        <Route
                          path="/scans/:id/services/subnets/:detailId/vulnerabilities"
                          exact
                          component={SubnetsServicesVulnerabilities}
                        />
                        <Route
                          path="/scans/add/newscan"
                          component={AddNewScan}
                        />
                        <Route
                          path="/scans/add/newscanSubnet"
                          component={AddNewScanSubnet}
                        />
                        <Route path="/info" component={InfoMain} />
                        <Route
                          path="/vulnerabilities/:id"
                          exact
                          component={VulnerabilityView}
                        />
                        <Route
                          path="/vulnerabilities/subnets/:id"
                          exact
                          component={VulnerabilitySubnetView}
                        />
                        <Route path="/reports" exact component={ReportsPage} />
                        {/* <Route
                        path={'/performers'}
                        component={MainPerformerPage}
                      /> */}
                        <Route
                          path="/scheduled-scans"
                          exact
                          component={ScheduledScansPage}
                        />
                        <Route path="/history" exact component={HistoryPage} />
                        <Route
                          path="/history/:id"
                          exact
                          component={HistoryPageItem}
                        />
                        <Route path="/scans" exact component={ScansList} />
                        <Route path="/api" exact component={ApiPage} />
                        <Route
                          path="/reference-data"
                          exact
                          component={ReferenceData}
                        />
                        <Route path="/gdpr" exact component={ReferenceData} />
                        <Route path="/welcome" component={WelcomePage} />
                        <Route path="/subnet" component={SubnetPage} />
                        {/* <Route path="/pentesters" component={PentestersPage}/> */}
                        <Route
                          path="/analytics/:id"
                          component={AnalyticHistoryPage}
                        />
                        <Route
                          path="/analytics"
                          component={AnalyticHistoryPageMain}
                        />
                        <Route path="/contact" component={ContactUsPage} />
                        <Route component={NotFound} />
                      </Switch>
                    </ContentContainer>
                  </Body>
                </AppLayout>
              ) : null}
            </ErrorMainBoundary>
          </Suspense>
        </Onboarding>
      </Router>
    </>
  );
};

type AppProps = {
  setUser: (value: InfoProps) => void;
  notification: any;
  clearNotification: () => void;
  tour: boolean;
  email: string | null;
  lang: string | null;
  groups: string[];
};

const App: FC<AppProps> = ({
  setUser,
  notification,
  clearNotification,
  email,
  lang,
  groups = [],
}) => {
  const [activeUserProfile, setActiveUserProfile] = useState<
    UserProfile | undefined
  >(undefined);
  const oktaObject = useOktaAuth();
  const [toast, setToast] = useState<{
    text: string;
    type: Severity;
    open: boolean;
  }>({
    text: "",
    type: "warning",
    open: false,
  });
  const [text, setText] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    if (notification.open) {
      setToast({
        ...notification,
      });
    }
  }, [notification]);

  useEffect(() => {
    if (process.env.REACT_APP_SECURITY === "okta") {
      if (oktaObject?.authState.accessToken) {
        oktaObject?.oktaAuth.getUser().then((user) => {
          setUser(user);
        });
      }
    }
  }, [oktaObject?.authState, oktaObject?.oktaAuth]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <UserContext.Provider
      value={{
        activeUserProfile,
        setActiveUserProfile,
        clearActiveUserProfile: () => {
          setActiveUserProfile(undefined);
        },
      }}
    >
      <ToastContext.Provider
        value={{
          setParams: (params: any) => setToast({ ...toast, ...params }),
        }}
      >
        <HeaderContext.Provider
          value={{
            header: text,
            setHeader: (val: string) => setText(val),
          }}
        >
          <Toast
            noteType={toast.type}
            text={toast.text}
            openProps={toast.open}
            clearHandler={() => {
              clearNotification();
            }}
          />
          {process.env.REACT_APP_SECURITY === "okta" ? (
            <AppInner
              email={email}
              pintester={groups?.[0] === "XSIG_PENTESTER"}
            />
          ) : (
            <KeycloakHOC>
              <AppInner
                email={email}
                pintester={groups?.[0] === "XSIG_PENTESTER"}
              />
            </KeycloakHOC>
          )}
        </HeaderContext.Provider>
      </ToastContext.Provider>
    </UserContext.Provider>
  );
};

export default connect(
  (state: RootState) => ({
    notification: state.notification,
    tour: state.settings.tour,
    email: state.settings.email,
    lang: state.settings.lang,
    groups: state.settings.groups,
  }),
  {
    setUser,
    clearNotification,
  }
)(App);
