import { AppThunk } from "../index";
import { ActionCreator } from "redux";
import { getVulnerabilities, getVulnerabilitiesByService } from "../../api/api";
import {
  GET_VULNERABILITIES,
  GET_VULNERABILITIES_COUNT,
  GET_VULNERABILITIES_DATE,
  GET_VULNERABILITY_BY_ID,
  GET_VULNERABILITY_BY_ID_LOADING,
  GET_VULNERABILITY_BY_ID_CHECKS,
  GET_VULNERABILITY_HISTORY,
  GET_VULNERABILITY_MARKS,
} from "../constants/vulnerabilities";
import { api } from "../../api/request";

const constObj = {
  days: 10,
  cvss3: 12,
  cvss2: 14,
};

export const getVulnerabilitiesThunk: ActionCreator<any> =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const resp = await getVulnerabilities();

      if (resp.data) {
        dispatch({
          type: GET_VULNERABILITIES,
          payload: resp.data,
        });
        const filtered = resp?.data?.reduce((acc: any, curr: any) => {
          const finded = acc.findIndex(
            (item: any) => item.hostname === curr.hostname
          );
          if (finded !== -1) {
            acc[finded] = {
              ...acc[finded],
              hostname: acc[finded].hostname,
              [curr.severity]: acc[finded][curr.severity] + 1,
            };
            return acc;
          } else {
            return [
              ...acc,
              {
                hostname: curr.hostname,
                critical: 0,
                high: 0,
                medium: 0,
                low: 0,
                [curr.severity]: 1,
              },
            ];
          }
        }, []);

        dispatch({
          type: GET_VULNERABILITIES_COUNT,
          payload: Boolean(filtered.length)
            ? filtered.sort((a: any, b: any) => b.critical - a.critical)
            : [],
        });
        dispatch({
          type: GET_VULNERABILITIES_DATE,
          payload: Boolean(resp.data.length)
            ? resp.data
                .filter(
                  (item) =>
                    item.severity === "high" || item.severity === "critical"
                )
                .sort((a: any) => (a.severity === "critical" ? -1 : 1))
                .slice(0, 10)
                .sort((a: any, b: any) => {
                  const aKoef =
                    (a.daysFromLastError - b.daysFromLastError) *
                      constObj.days +
                    a.cvss3 * constObj.cvss3 +
                    a.cvss2 * constObj.cvss2;
                  const bKoef =
                    (a.daysFromLastError - b.daysFromLastError) *
                      constObj.days +
                    b.cvss3 * constObj.cvss3 +
                    b.cvss2 * constObj.cvss2;

                  return bKoef - aKoef;
                })
            : [],
        });
      }
    } catch (e) {}
  };

export const getVulnerabilityById: ActionCreator<any> =
  (serviceId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_VULNERABILITY_BY_ID_LOADING,
        payload: true,
      });
      const res = await getVulnerabilitiesByService(serviceId);
      if (res?.data?.length) {
        dispatch({
          type: GET_VULNERABILITY_BY_ID,
          payload: res.data,
          id: serviceId,
        });
      } else {
        dispatch({
          type: GET_VULNERABILITY_BY_ID,
          payload: [],
          id: serviceId,
        });
      }
      dispatch({
        type: GET_VULNERABILITY_BY_ID_LOADING,
        payload: false,
      });
    } catch (e) {
      dispatch({
        type: GET_VULNERABILITY_BY_ID_LOADING,
        payload: false,
      });
    }
  };

export const getVulnerabilityByIdChecks: ActionCreator<any> =
  (scanId: number): AppThunk<any> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(`/scans/${scanId}/checks`);
      if (res.data.length) {
        dispatch({
          type: GET_VULNERABILITY_BY_ID_CHECKS,
          payload: res.data,
          id: scanId,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const changeVulnerability: ActionCreator<any> =
  (params: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      const res = await api.put(`/scans/vulnerabilities/severity`, params);
      if (res.data) {
        return true;
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

export const getVulnerabilityHistory: ActionCreator<any> =
  (id: number): AppThunk<any> =>
  async (dispatch) => {
    try {
      const res = await api.get(`/scans/vulnerabilities/${id}/history`);
      if (res.data && res.data.length) {
        dispatch({
          type: GET_VULNERABILITY_HISTORY,
          payload: res.data,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const getVulnerabilitiesMarksId: ActionCreator<any> =
  (id: number): AppThunk<any> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(`/vulnerabilities/${id}/howto`);
      if (res.data) {
        dispatch({
          type: GET_VULNERABILITY_MARKS,
          payload: res.data,
          id,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
