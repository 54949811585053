import { Box, Button, styled, Typography } from "@material-ui/core";
import { useLocalStorageState } from "ahooks";
import { createContext } from "react";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CardinalOrientation, Step, Walktour } from "walktour";

const Tooltip = styled(Box)({
  padding: "8px",
  background: "#ffffff",
  boxShadow: `0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)`,
  borderRadius: "6px",
  width: "280px",
  outline: "red",
});

const StepContent = styled(Typography)({
  fontSize: "14px",
  color: "rgba(0, 0, 0, .6)",
});
const StepTitle = styled(Typography)({
  fontSize: "16px",
  color: "rgba(0,0,0, .87)",
});

type PatchedStep = Omit<Step, "selector"> & {
  selector: Step["selector"] | null;
};

const ONBOARDING_PASSED_FLAG_NAME = "onboarding-passed";

export const OnboardingContext = createContext<{
  isPassed: boolean;
  setIsPassed(isPassed: boolean): void;
}>({
  isPassed: false,
  setIsPassed() {},
});

export const Onboarding: FC = ({ children }) => {
  const [isOnboardingPassed, setIsOnboardingPassed] =
    useLocalStorageState<boolean>(ONBOARDING_PASSED_FLAG_NAME, {
      defaultValue: false,
    });

  const { t, ready } = useTranslation(["pageAddNewScan", "tooltip"]);
  const { replace } = useHistory();
  const ensureLocation = useCallback(
    (path: string) => {
      if (window.location.pathname !== path) {
        replace(path);
      }
    },
    [replace]
  );

  const steps = useMemo(
    (): Array<PatchedStep> =>
      ready
        ? [
            {
              description: t("tooltip:startContent"),
              title: t("tooltip:startTitle"),
              selector: null,
              customNextFunc(tourLogic) {
                ensureLocation("/");
                console.log(tourLogic.stepContent.selector);
                tourLogic.next();
              },
            },
            {
              selector: '[data-onboarding-target="resources-menu-option"]',
              description: t("tooltip:goToAsset"),
              orientationPreferences: [CardinalOrientation.SOUTHWEST],
              nextOnTargetClick: true,
              disableClose: true,
              disableNext: true,
              customNextFunc(tourLogic) {
                ensureLocation("/assets");
                setTimeout(() => {
                  tourLogic.next();
                }, 500);
              },
            },
            {
              selector: '[data-onboarding-target="open-add-asset-dialog"]',
              description: t("tooltip:addAssetTitle"),
              nextOnTargetClick: true,
              disableClose: true,
              disableNext: true,
            },
            {
              selector: '[data-onboarding-target="add-asset-button"]',
              allowForeignTarget: true,
              nextOnTargetClick: true,
              disableMask: true,
              description: t("tooltip:clickAddAsset"),
              orientationPreferences: [CardinalOrientation.SOUTH],
              disableClose: true,
              disableNext: true,
              customNextFunc(tourLogic) {
                setTimeout(() => {
                  tourLogic.next();
                }, 500);
              },
            },
            {
              selector: '[data-onboarding-target="start-scan-button"]',
              allowForeignTarget: true,
              nextOnTargetClick: true,
              disableMask: true,
              description: t('tooltip:scanAddedResource'),
              customNextFunc(tourLogic) {
                ensureLocation("/scans/add/newscan");
                setTimeout(() => {
                  tourLogic.next();
                }, 800);
              },
              disableClose: true,
              disableNext: true,
            },
            {
              selector: null,
              description: t("tooltip:chooseAssetForward"),
              disableCloseOnClick: true,
              title: t("tooltip:chooseAssetScan"),
            },
            {
              selector: '[data-onboarding-target="choose-scan-type-button"]',
              description: "",
              nextOnTargetClick: true,
              disableCloseOnClick: true,
              disableMask: true,
            },
            {
              selector: null,
              disableCloseOnClick: true,
              description: t("tooltip:chooseScanTypeContent"),
            },
            {
              selector: '[data-onboarding-target="schedule-scan-button"]',
              description: "",
              nextOnTargetClick: true,
              disableCloseOnClick: true,
              disableMask: true,
            },
            {
              selector: '[data-onboarding-target="scan-now-button"]',
              title: t("tooltip:timeTitle"),
              nextOnTargetClick: true,
              disableCloseOnClick: true,
              description: t("tooltip:timeTitleContent"),
              disableClose: true,
              disableNext: true,
            },
            {
              selector: null,
              description: t("tooltip:previewContent"),
              disableCloseOnClick: true,
              title: t("tooltip:previewTitle"),
            },
            {
              selector: '[data-onboarding-target="preview-scan-button"]',
              description: "",
              nextOnTargetClick: true,
              disableMask: true,
              disableNext: true,
              disableClose: true,
              customNextFunc(tourLogic) {
                setTimeout(() => {
                  tourLogic.next();
                }, 800);
              },
            },
            {
              selector: null,
              title: t("tooltip:finishTitle"),
              disableCloseOnClick: true,
              description: t("tooltip:finishContent"),
            },
            {
              selector: '[data-onboarding-target="scans-menu-option"]',
              description: t('tooltip:scansPage'),
              disableCloseOnClick: true,
              nextOnTargetClick: true,
              
            },
            {
              selector: '[data-onboarding-target="schedule-menu-option"]',
              description: t('tooltip:schedulePage'),
              disableCloseOnClick: true,
              nextOnTargetClick: true,
              
            },
            {
              selector: '[data-onboarding-target="reports-menu-option"]',
              description: t('tooltip:reportsPage'),
              disableCloseOnClick: true,
              nextOnTargetClick: true,
            },
          ]
        : [],
    [ensureLocation, ready, t]
  );

  return (
    <OnboardingContext.Provider
      value={{
        isPassed: isOnboardingPassed,
        setIsPassed: setIsOnboardingPassed,
      }}
    >
      {!isOnboardingPassed && (
        <Walktour
          steps={steps as Array<Step>}
          customCloseFunc={() => {
            setIsOnboardingPassed(true);
          }}
          customNextFunc={(tourLogic) => {
            console.log(tourLogic.stepContent.selector);
            setTimeout(() => {
              tourLogic.next();
            }, 300);
          }}
          customTitleRenderer={(title) => (
            <>{title ? <StepTitle>{title}</StepTitle> : undefined}</>
          )}
          customTooltipRenderer={(tourLogic) => {
            const isLastStep =
              tourLogic?.stepIndex === (tourLogic?.allSteps.length ?? 0) - 1;
            if (
              !tourLogic?.stepContent.title &&
              !tourLogic?.stepContent.description
            ) {
              return <></>;
            }
            return (
              <Tooltip>
                <Box padding={"16px"}>
                  {tourLogic?.stepContent.title && (
                    <StepTitle>{tourLogic?.stepContent.title}</StepTitle>
                  )}

                  <Box
                    marginTop={
                      tourLogic?.stepContent.title ? "16px" : undefined
                    }
                  >
                    {tourLogic?.stepContent.description ? (
                      <StepContent>
                        {tourLogic?.stepContent.description}
                      </StepContent>
                    ) : null}
                  </Box>
                </Box>
                {!tourLogic?.stepContent.disableClose ||
                !tourLogic?.stepContent.disableNext ? (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    marginTop={"15px"}
                  >
                    {!tourLogic?.stepContent.disableClose && (
                      <Button
                        onClick={() => {
                          if (isLastStep) {
                            tourLogic?.goToStep(0);
                          } else {
                            tourLogic?.close();
                          }
                        }}
                      >
                        {isLastStep
                          ? t("tooltip:repeat")
                          : t("pageAddNewScan:controls.skip")}
                      </Button>
                    )}
                    {!tourLogic?.stepContent.disableNext && (
                      <Button
                        onClick={() => {
                          tourLogic?.[isLastStep ? "close" : "next"]();
                        }}
                        variant="contained"
                        color="primary"            
                      >
                        {isLastStep
                          ? t("tooltip:finishTooltip")
                          : t("pageAddNewScan:controls.ok")}
                      </Button>
                    )}
                  </Box>
                ) : undefined}
              </Tooltip>
            );
          }}
        />
      )}
      {children}
    </OnboardingContext.Provider>
  );
};
