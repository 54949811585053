import { FC } from "react";

const AssetsIcon: FC<{ active: boolean }> = ({ active }) => {
  return active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0V20C3.9 19.5 0 15.2 0 10C0 4.8 3.9 0.5 9 0ZM11 0V9H20C19.5 4.2 15.8 0.5 11 0ZM11 11V20C15.7 19.5 19.5 15.8 20 11H11Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0V20C3.9 19.5 0 15.2 0 10C0 4.8 3.9 0.5 9 0ZM11 0V9H20C19.5 4.2 15.8 0.5 11 0ZM11 11V20C15.7 19.5 19.5 15.8 20 11H11Z"
        fill="#a7b1c2"
      />
    </svg>
  );
};

export default AssetsIcon;
