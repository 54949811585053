import { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import LikeIcon from "../icons/LikeIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "280px",
      height: "150px",
      borderRadius: "6px",
      boxShadow:
        "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
      background: theme.palette.white.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "24px",
    },
    title: {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    icons: {
      padding: "20px",
      background: theme.palette.pink.main,
      borderRadius: "50%",
      marginTop: "14px",
    },
  })
);

const NotFoundComponent: FC = () => {
  const styles = useStyles();
  const [t] = useTranslation(["pageVulnerability"]);
  return (
    <Box className={styles.container}>
      <Typography>{t("pageVulnerability:vulnerabilityNotFound")}</Typography>
      <Box className={styles.icons}>
        <LikeIcon />
      </Box>
    </Box>
  );
};

export default NotFoundComponent;
