import React, { FC, forwardRef, useRef, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import {
  Box,
  createStyles,
  Hidden,
  Link as LinkBottom,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DashboardIcon from "components/icons/DashboardIcon";
import AssetsIcon from "components/icons/AssetsIcon";
import ScansIcon from "components/icons/ScansIcon";
import VulnerabilitiesIcon from "components/icons/VulnerabilitiesIcon";
import ReferenceDataIcon from "components/icons/ReferenceDataIcon";
import MailIcon from "components/icons/MailIcon";
import IconMono from "../icons/IconMono";
import CloudIcon from "../icons/CloudIcon";
import BugReportIcon from "../icons/BugReportIcon";
import PersonIcon from "components/icons/Person";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import ScheduleIcon from "@material-ui/icons/Schedule";
import DescriptionIcon from "@material-ui/icons/Description";
import Chip from "@material-ui/core/Chip";
import { Condition } from "../Condition";
import CloseIcon from "@material-ui/icons/Close";
import EqualizerIcon from "../../icons/EqualizerIcon";
import Logo from "../../../assets/images/Logo.png";

const SidebarLink = styled(NavLink) <{ opened: boolean }>`
  font-size: 1rem;
  padding: ${(props) => (props.opened ? "12px 16px" : "3px")};
  display: grid;
  grid-template-columns: 21px auto;
  grid-gap: 32px;
  font-weight: 500;
  height: ${(props) => (props.opened ? "auto" : "40px")};
  justify-content: ${(props) => (props.opened ? "normal" : "center")};
  color: #000;
  margin: 0 10px 0 7px;
  border-radius: 4px;

  & > span:first-letter {
    text-transform: capitalize;
  }

  &:hover {
    background: #dfdfdf;
    span {
      opacity: 1;
    }
  }

  & > * {
    align-self: center;
  }

  span {
    color: #000;
    font-weight: 600;
    opacity: 0.5;
  }

  &.is-active {
    background: #dfdfdf;

    span {
      color: #000;
      opacity: 1;
    }
  }
`;
const SpanWrapper = styled.span`
  margin-left: 5px;
  font-size: 10px;
  text-transform: uppercase;
  border: 1px solid #fa49a0;
  padding: 2px 5px;
  border-radius: 10px;
  color: #fa49a0 !important;
`;

const Link = styled.a`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      height: "70px",
      display: "flex",
      justifyContent: "flex-start",
      padding: "0 24px",
      alignItems: "center",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.white.main,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        position: "relative",
      },
    },
    drawerOpen: {
      width: `${250}px`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: theme.palette.white.main,
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    drawerClose: {
      width: `40px`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: theme.palette.white.main,
    },
    menuIcon: {
      color: "#a7b1c2",
    },
    link: {
      color: theme.palette.white.main,
    },
    linkBottom: {
      "&:hover": {
        color: theme.palette.pink.main,
      },
    },
    iconContainer: {
      position: "absolute",
      right: "21px",
      cursor: "pointer",
    },
  })
);

const useSmallStyle = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: "5px",
      borderRadius: "6px",
      height: "16px",
      color: theme.palette.headerColor.main,
      "& .MuiChip-label": {
        opacity: "1 !important",
        padding: "0 5px",
        fontSize: "9px",
      },
    },
  })
);

interface SidebarOptionProps {
  text: string;
  to: string;
  exact: boolean;
  "data-onboarding-target"?: string;
  icon: React.FunctionComponent<any>;
  opened: boolean;
  mailTo?: string;
  func?: boolean;
  label?: string;
  location: {
    pathname: string;
  };
  classJoy?: string;
  beta?: boolean;
}

const SidebarOption = forwardRef<HTMLAnchorElement, SidebarOptionProps>((props, ref) => {
  const [state, setState] = useState(false);
  const styles = useSmallStyle();
  return (
    <SidebarLink
      onMouseEnter={() => setState(true)}
      onMouseLeave={() => setState(false)}
      className={props.classJoy}
      // @ts-ignore
      ref={ref}
      {...(props.mailTo?.length
        ? {
          onClick: (e) => {
            e.preventDefault();
            window.location.href = props.mailTo as string;
          },
        }
        : {})}
      {...(props.func
        ? {
          onClick: (e) => {
            e.preventDefault();
            if (props.func) {
              window.open("https://gdpr.xsignal.io", "_blank");
            }
          },
        }
        : {})}
      data-onboarding-target={props['data-onboarding-target']}
      isActive={(match, location) => {
        if (match) {
          return true;
        }
        const locState = location.state as { activeLinkAlias: string };
        return (
          Boolean(locState?.activeLinkAlias) &&
          locState.activeLinkAlias === props.to
        );
      }}
      opened={props.opened}
      exact={props.exact}
      activeClassName="is-active"
      to={props.to}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <props.icon
          active={
            `/${props.location.pathname?.split("/")?.[1]}` === props.to || state
          }
          style={
            `/${props.location.pathname?.split("/")?.[1]}` === props.to || state
              ? { color: "#000" }
              : { color: "#a7b1c2" }
          }
        />
      </Box>
      {props.opened ? (
        <span>
          {props.text}
          <Condition match={Boolean(props.beta)}>
            <Chip
              className={styles.chip}
              label={"BETA"}
              color={"primary"}
              variant={"outlined"}
              size={"small"}
            />
          </Condition>
          <Condition match={Boolean(props.label)}>
            <SpanWrapper>{props.label}</SpanWrapper>
          </Condition>
        </span>
      ) : null}
    </SidebarLink>
  );
});

SidebarOption.defaultProps = {
  mailTo: "",
};

type DrawerComponentProps = {
  opened: boolean;
  setOpen: (val: boolean) => void;
  pintester?: boolean;
  open: boolean;
  setClose: () => void;
};

const DrawerComponent: FC<DrawerComponentProps> = ({
  opened,
  pintester,
  open,
  setClose,
}: DrawerComponentProps) => {
  const style = useStyle();
  const [t] = useTranslation("menu");
  const location = useLocation();
  const history = useHistory();
  const ref = useRef(null);
  const theme = useTheme();
  const isMdup = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    history.listen((_, action) => {
      if (!isMdup && action === "PUSH") {
        setClose();
      }
    });
  }, [history]);

  return (
    <Drawer
      anchor={isMdup ? "left" : "top"}
      variant={isMdup ? "permanent" : "temporary"}
      classes={{
        paper: opened ? style.drawerOpen : style.drawerClose,
      }}
      onClose={() => setClose()}
      open={open}
    >
      <div className={style.toolbar}>
        <NavLink to="/">
          <img style={{ width: "76%" }} src={Logo} alt="Logo" />
        </NavLink>

        <Hidden mdUp={true}>
          <Box className={style.iconContainer} onClick={() => setClose()}>
            <CloseIcon fontSize={"large"} />
          </Box>
        </Hidden>
      </div>

      <Divider />

      <Box display="grid" gridTemplateRows="1fr 50px" height="100%">
        <Box marginTop="10px">
          <SidebarOption
            text={t("main")}
            exact={true}
            to="/"
            icon={DashboardIcon}
            opened={opened}
            location={location}
          />
          <SidebarOption
            text={t("assets")}
            exact={false}
            to="/assets"
            icon={AssetsIcon}
            opened={opened}
            location={location}
            data-onboarding-target='resources-menu-option'
            ref={ref}
          />
          <SidebarOption
            text={t("subpath")}
            exact={false}
            to="/subnet"
            icon={CloudIcon}
            opened={opened}
            location={location}
            beta
          />

          <SidebarOption
            text={t("scans")}
            exact={false}
            to="/scans"
            data-onboarding-target='scans-menu-option'
            icon={ScansIcon}
            opened={opened}
            location={location}
          />

          <SidebarOption
            text={t("schedule")}
            exact={false}
            to="/scheduled-scans"
            data-onboarding-target='schedule-menu-option'
            icon={ScheduleIcon}
            opened={opened}
            location={location}
          />

          <SidebarOption
            text={t("reports")}
            exact={false}
            to="/reports"
            data-onboarding-target='reports-menu-option'
            icon={BugReportIcon}
            opened={opened}
            location={location}
          />
          <SidebarOption
            text={t("vulnerabilities")}
            exact={false}
            to="/vulnerabilities"
            icon={VulnerabilitiesIcon}
            opened={opened}
            location={location}
          />

          <SidebarOption
            text={"API"}
            exact={false}
            to="/api"
            icon={DescriptionIcon}
            opened={opened}
            location={location}
          />
          <SidebarOption
            text={t("newCve")}
            exact={false}
            to="/history"
            icon={PriorityHighIcon}
            opened={opened}
            location={location}
          />
          <SidebarOption
            text={t("diffDiagnostics")}
            exact={false}
            to="/analytics"
            icon={EqualizerIcon}
            opened={opened}
            location={location}
          />
          {/*<SidebarOption*/}
          {/*  text={t('settings')}*/}
          {/*  exact={false}*/}
          {/*  to='/settings'*/}
          {/*  icon={SettingsIcon}*/}
          {/*  opened={opened}*/}
          {/*  location={location}*/}
          {/*/>*/}

          {/* <SidebarOption
            text={t('performers')}
            exact={false}
            to='/performers'
            icon={PersonIcon}
            opened={opened}
            location={location}
          />
          {
            pintester ?
              <SidebarOption
                text={t('partners')}
                exact={false}
                to='/pentesters'
                icon={PersonOutlineIcon}
                opened={opened}
                location={location}
              /> :
              null
          } */}
          <SidebarOption
            text={t("contact_us")}
            exact={false}
            to="/contact"
            icon={MailIcon}
            opened={opened}
            location={location}
          />
          <Condition match={process.env.REACT_APP_SECURITY === "okta"}>
            <SidebarOption
              text={t("compliance")}
              exact={false}
              func
              to="/gdpr"
              icon={ReferenceDataIcon}
              opened={opened}
              location={location}
              beta
            />
          </Condition>
          {/*<SidebarOption*/}
          {/*  text={t('info')}*/}
          {/*  exact={false}*/}
          {/*  to='/info'*/}
          {/*  icon={Lifebuoy}*/}
          {/*  opened={opened}*/}
          {/*  location={location}*/}
          {/*/>*/}
        </Box>

        <Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="center"
            height="100%"
            alignItems="center"
          >
            <Typography className={style.link}>
              <Link href={"https://xsignal.io"} target={"_blank"}>
                <LinkBottom className={style.linkBottom}>
                  Перейти на сайт
                </LinkBottom>
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
