import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Table, TableBody, Typography } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";

import { getPlans } from "api/api";
import BoxView from "components/shared/BoxView";
import { setNotification } from "store/action/notification";
import { useTranslation } from "react-i18next";
import StartModalComponent from "../../../../comps/InfoComponents/StartModalComponent";
import { icons } from "components/comps/TableComponents/WithIcon";
import { useHistory } from "react-router-dom";

const StyleTypography = withStyles({
  root: {
    fontSize: "18px",
    color: "#000",
    marginLeft: "12px",
    textDecoration: "underline",
    transition: "all .2s ease",
  },
})(Typography);

const BoxHover = withStyles((theme: Theme) => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      "& p": {
        color: theme.palette.pink.main,
        textDecoration: "none",
      },
    },
  },
}))(Box);

const LambdaList = () => {
  const [t] = useTranslation(["pageDashboard", "pageScans"]);
  const dispatch = useDispatch();
  const [services, setServices] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    getPlans()
      .then((response) => {
        setServices(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch(
          setNotification({ text: error?.message, type: "error", open: true })
        );
      });
  }, []);

  return (
    <BoxView
      title={t("pageScans:checksName")}
      isLoading={isLoading}
      style={
        services.length
          ? {}
          : {
              position: "relative",
              height: "400px",
              background: "#fff",
              border: "none !important",
            }
      }
    >
      <Table size="small">
        <TableBody>
          {services?.length ? (
            services.map((service, index) => {
              if (service?.plan === "quick" || service?.plan === "full")
                return null;
              const Elem = icons?.[service?.plan];
              return (
                <BoxHover
                  onClick={() => history.push("/scans/add/newscan")}
                  key={index}
                  display={"flex"}
                  alignItems={"center"}
                  margin={"12px 0"}
                >
                  {Elem ? (
                    <Box>
                      <Elem />
                    </Box>
                  ) : null}
                  <StyleTypography>{service.title}</StyleTypography>
                </BoxHover>
              );
            })
          ) : (
            <StartModalComponent
              typeShowModal={"pageDashboard:showScan"}
              title={t("pageDashboard:dontCheck")}
              titleDescr={t("pageDashboard:forWorkStartScan")}
            />
          )}
        </TableBody>
      </Table>
    </BoxView>
  );
};

export default LambdaList;
