export const GET_VULNERABILITIES = "vulnerabilities/get";
export const GET_VULNERABILITIES_COUNT = "vulnerabilities/get/count";
export const GET_VULNERABILITIES_DATE = "vulnerabilities/get/count/date";
export const GET_VULNERABILITY_BY_ID = "vulnerabilities/get/by/id";
export const GET_VULNERABILITY_BY_ID_LOADING =
  "vulnerabilities/get/by/id/loading";
export const GET_VULNERABILITY_BY_ID_CHECKS =
  "vulnerabilities/get/by/id/checks";
export const GET_VULNERABILITY_HISTORY = "vulnerabilities/get/by/id/history";
export const GET_VULNERABILITY_MARKS = "vulnerabilities/get/by/marks";
