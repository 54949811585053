import {
  Vulnerability,
  VulnerabilitiesByScan,
  VulnerabilityType,
} from "./dashboard.type";

export const INITIAL_VULNERABILITIES: Vulnerability[] = [
  {
    type: VulnerabilityType.CRITICAL,
    count: 0,
  },
  {
    type: VulnerabilityType.HIGH,
    count: 0,
  },
  {
    type: VulnerabilityType.MEDIUM,
    count: 0,
  },
  {
    type: VulnerabilityType.LOW,
    count: 0,
  },
];

export const INITIAL_VULNERABILITIES_LIST: VulnerabilitiesByScan[] = [
  {
    index: "0",
    vulnerabilities: INITIAL_VULNERABILITIES,
  },
];

type WidgetType = {
  [key: string]: {
    title: string;
    background: string;
    color: string;
  };
};

type ColorType = {
  [key: string]: string;
};

export const VULNERABILITY_CONFIG: WidgetType = {
  [VulnerabilityType.CRITICAL]: {
    title: "critical",
    background: "#ED5565",
    color: "#ffffff",
  },
  [VulnerabilityType.HIGH]: {
    title: "high",
    background: "#f8ac59",
    color: "#ffffff",
  },
  [VulnerabilityType.MEDIUM]: {
    title: "medium",
    background: "#23c6c8",
    color: "#ffffff",
  },
  [VulnerabilityType.LOW]: {
    title: "low",
    background: "#1c84c6",
    color: "#ffffff",
  },
};

export const COLORS_CONFIG: ColorType = {
  [VulnerabilityType.CRITICAL]: "#EB5757",
  [VulnerabilityType.HIGH]: "#F2994A",
  [VulnerabilityType.MEDIUM]: "#FA49A0",
  [VulnerabilityType.LOW]: "#56CCF2",
};
