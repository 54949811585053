import React from "react";
import { useHistory } from "react-router";
import { Grid, Typography, Box } from "@material-ui/core";

import DotsLoader from "components/shared/Loaders/DotsLoader";
import { ReactComponent as UmbrellaIcon } from "assets/icons/low.svg";
import { ReactComponent as UserSecretIcon } from "assets/icons/medium.svg";
import { ReactComponent as ExclamationTriangleIcon } from "assets/icons/high.svg";
import { ReactComponent as FlashIcon } from "assets/icons/critical.svg";
import { VulnerabilityType } from "../../core/dashboard.type";
import { COLORS_CONFIG, VULNERABILITY_CONFIG } from "../../core/dashboard";
import VulnerabilityCounterWidget from "./VulnerabilityCounterWidget";
import "./VulnerabilityCounter.css";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const Icons = {
  [VulnerabilityType.CRITICAL]: <FlashIcon />,
  [VulnerabilityType.HIGH]: <ExclamationTriangleIcon />,
  [VulnerabilityType.MEDIUM]: <UserSecretIcon />,
  [VulnerabilityType.LOW]: <UmbrellaIcon />,
};

export type VulnerabilityCounterProps = {
  type: VulnerabilityType;
  count: number;
  isLoading?: boolean;
  lvl: string;
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.87)",
      opacity: ".5",
    },
    titleValue: {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.87)",
    },
  })
);

export const VulnerabilityCounter = ({
  lvl,
  type,
  count,
  isLoading,
}: VulnerabilityCounterProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  const styles = useStyles();
  const goToVulnerabilitiesPage = () => {
    history.push(`/vulnerabilities?severity=${type}`);
  };

  return (
    <VulnerabilityCounterWidget
      onClick={goToVulnerabilitiesPage}
      colorActive={lvl === type ? COLORS_CONFIG[lvl as string] : ""}
      style={{
        // color: VULNERABILITY_CONFIG[type].color,
        cursor: "pointer",
      }}
    >
      <Grid container alignItems="center" justifyContent={"space-between"}>
        <Grid item xs={3}>
          {Icons[type]}
        </Grid>
        <Grid item xs={9}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Typography className={styles.title}>
              {t(`lvl.${VULNERABILITY_CONFIG[type].title}`)}:&nbsp;
            </Typography>
            <Typography className={styles.titleValue}>
              {isLoading ? <DotsLoader /> : count}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </VulnerabilityCounterWidget>
  );
};
