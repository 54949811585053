import { createReducer } from "utils/createReducers";
import * as constants from "../constants/assets";
import { Asset } from "models/Asset";
// import {GET_ASSETS_MAIN_LOADING} from "../constants/assets";

type stateProps = {
  data: Asset[];
  maximum: boolean;
  loading: boolean;
  tokensAssets: any;
  verifiedAssets: any;
  main: Asset[];
  loadingMain: boolean;
};

const initState: stateProps = {
  data: [],
  maximum: false,
  loading: false,
  tokensAssets: {},
  verifiedAssets: {},
  main: [],
  loadingMain: false,
};

export default createReducer(initState, {
  [constants.GET_ASSETS]: (state, { payload }: any) => ({
    ...state,
    main: payload,
  }),
  [constants.GET_ASSETS_MAIN_LOADING]: (state, { payload }: any) => ({
    ...state,
    loadingMain: payload,
  }),
  [constants.GET_ASSETS_ARCHIVE]: (state, { payload }: any) => ({
    ...state,
    data: payload,
  }),
  [constants.SET_ASSETS_MAXIMUM]: (state, { payload }: any) => ({
    ...state,
    maximum: payload,
  }),
  [constants.SET_TOKENS_ASSETS]: (state, { payload }: any) => ({
    ...state,
    tokensAssets: {
      ...state.tokensAssets,
      [payload.field]: payload.value,
    },
  }),
  [constants.SET_ASSETS_VERIFIED]: (state, { payload }: any) => ({
    ...state,
    verifiedAssets: {
      ...state.verifiedAssets,
      [payload.field]: payload.value,
    },
  }),
});
