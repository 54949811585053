import { useCallback, useContext, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

import UserContext from "../UserContext";
import { apiUrl } from "./api";
import {
  clearActiveUserToken,
  retrieveActiveUserToken,
} from "components/core/auth";

type Response = {
  data: any;
  message: string;
  status: number;
};

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Authorization: retrieveActiveUserToken(),
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${retrieveActiveUserToken()}`;
    return config;
  },
  (error) => {
    return error;
  }
);

api.interceptors.response.use(
  (response) => {
    return isStandardResponse(response)
      ? {
          ...response,
          data: response?.data?.data,
          message: response?.data?.message,
        }
      : response;
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      if (process.env.REACT_APP_SECURITY === "okta") {
        if (Boolean(/asset/gi.test(error?.response?.data?.errorCode))) {
          throw error;
        }
        clearActiveUserToken();
        if (window) {
          window.location.replace(`${window.location.origin}`);
        }
      }
    }
    throw error;
  }
);

const isStandardResponse = (
  response: AxiosResponse<Response> | AxiosResponse<any>
): response is AxiosResponse<Response> => {
  return (
    (response.data as Response).status !== undefined &&
    (response.data as Response).message !== undefined &&
    (response.data as Response).data !== undefined
  );
};

const makeRequest = async (url: string, options: any) => {
  const storedAuthToken = retrieveActiveUserToken();

  if (storedAuthToken) {
    options.headers = {
      ...options.headers,
      ...(retrieveActiveUserToken()
        ? { Authorization: `Bearer ${retrieveActiveUserToken()}` }
        : {}),
    };
  }

  options = {
    ...options,
    credentials: "include",
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      if (window) {
        window.location.replace(`${window.location.origin}`);
      }
      clearActiveUserToken();
    }
    throw await response.json();
  }

  return response.json();
};

const clearMakeRequest = async (url: string, options: any) => {
  const storedAuthToken = retrieveActiveUserToken();

  if (storedAuthToken) {
    options.headers = {
      ...options.headers,
      ...(retrieveActiveUserToken()
        ? { Authorization: `Bearer ${retrieveActiveUserToken()}` }
        : {}),
    };
  }

  options = {
    ...options,
    credentials: "include",
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      if (window) {
        window.location.replace(`${window.location.origin}`);
      }
      clearActiveUserToken();
    }
    throw response;
  }

  return response;
};

export const getRequest = (url: string, headers?: any) => {
  return makeRequest(url, {
    method: "GET",
    ...(headers ? headers : {}),
  });
};

export const postRequest = (url: string, body: any, headers?: any) => {
  return makeRequest(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(headers ? headers : {}),
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  });
};

export const clearGetRequest = (url: string, headers?: any) => {
  return clearMakeRequest(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...(headers ? headers : {}),
    },
  });
};

export const clearPostRequest = (url: string, body: any, headers?: any) => {
  return clearMakeRequest(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(headers ? headers : {}),
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  });
};

export const putRequest = (url: string, body: any) => {
  return makeRequest(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};

export const deleteRequest = (url: string) => {
  return makeRequest(url, {
    method: "DELETE",
  });
};

// @DEPRECATED: use useFetch2 (see below).
export const useFetch = (
  url: string,
  method: "GET" | "POST" | "DELETE" = "GET",
  body?: any
) => {
  const userContext = useContext(UserContext);

  const [inProcess, setInProcess] = useState<boolean>(true);
  const [error, setError] = useState<any | undefined>(undefined);
  const [data, setData] = useState<any | undefined>(undefined);

  useEffect(() => {
    const impl = async () => {
      try {
        let response: any = {};
        if (method === "GET") {
          response = (await getRequest(apiUrl(url))) as any;
        } else {
          response = (await postRequest(apiUrl(url), body)) as any;
        }
        setData(response);
        setInProcess(false);
      } catch (e: any) {
        if (e.status && (e.status === 401 || e.status === 403)) {
          if (userContext.activeUserProfile) {
            userContext.clearActiveUserProfile();
          }
        }
        setError(e);
        setInProcess(false);
      }
    };
    setInProcess(true);
    impl();
  }, [url, method, body, userContext]);

  return [data, inProcess, error];
};

export const useFetch2 = () => {
  const userContext = useContext(UserContext);

  return useCallback(
    async (
      url: string,
      method: "GET" | "POST" | "DELETE" = "GET",
      body?: any
    ) => {
      let data: any = undefined;
      let error: any = undefined;

      try {
        if (method === "GET") {
          data = (await getRequest(apiUrl(url))) as any;
        } else if (method === "POST") {
          data = (await postRequest(apiUrl(url), body)) as any;
        } else if (method === "DELETE") {
          await deleteRequest(apiUrl(url));
        }
      } catch (e: any) {
        if (e.status && (e.status === 401 || e.status === 403)) {
          if (userContext.activeUserProfile) {
            userContext.clearActiveUserProfile();
          }
        }
        error = e;
      }

      return { data, error };
    },
    [userContext]
  );
};
