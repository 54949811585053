import {
  createEntityAdapter,
  createReducer,
  createSelector,
} from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { Scan } from "models";
import { LoadingStatus } from "models/LoadingStatus";
import { RootState } from "store/index";
import { loadScans } from "store/action/scanList";

const sortByCreatedAt = (prev: Scan, next: Scan) => {
  if (DateTime.fromISO(prev.createdAt) > DateTime.fromISO(next.createdAt)) {
    return -1;
  }
  if (DateTime.fromISO(prev.createdAt) < DateTime.fromISO(next.createdAt)) {
    return 1;
  }

  return 0;
};

const getScansPerCurrentMonth = (scans: Scan[]): Scan[] => {
  return scans.filter((scan: Scan) =>
    DateTime.fromISO(scan.createdAt).hasSame(DateTime.now(), "month")
  );
};

const scanListAdapter = createEntityAdapter<Scan>({
  selectId: (scan) => scan.id,
  sortComparer: sortByCreatedAt,
});

const initialState = {
  loading: LoadingStatus.Idle,
  error: null,
  perCurrentMonth: [] as Scan[],
};

export const scanListReducer = createReducer(
  scanListAdapter.getInitialState(initialState),
  {
    [loadScans.pending.type]: (state) => {
      state.loading = LoadingStatus.Loading;
      state.error = null;
    },
    [loadScans.fulfilled.type]: (state, action) => {
      if (Boolean(action.payload?.length)) {
        scanListAdapter.setAll(state, action.payload);
        state.perCurrentMonth = getScansPerCurrentMonth(action.payload);
      } else {
        scanListAdapter.setAll(state, []);
      }
      state.loading = LoadingStatus.Succeeded;
      state.error = null;
    },
    [loadScans.rejected.type]: (state, action) => {
      state.loading = LoadingStatus.Failed;
      state.error = action.payload;
    },
  }
);

export const {
  selectById,
  selectIds,
  selectEntities,
  selectAll: selectScanList,
  selectTotal,
} = scanListAdapter.getSelectors((state: RootState) => state.scanList);

export const scanListLoadingStatus = createSelector(
  (state: RootState) => state,
  (state) => state.scanList.loading
);

export const scanList = createSelector(
  (state: RootState) => state,
  (state) => state.scanList
);

export const scanListError = createSelector(scanList, (state) => state.error);

export const scanPerCurrentMonth = createSelector(
  scanList,
  (state) => state.perCurrentMonth
);

const settingsGet = createSelector(
  (state: RootState) => state,
  (state) => state.settings
);

export const scanPerCurrentCalendarMonth = createSelector(
  settingsGet,
  (state) => state.limitSpent
);

export const subPaths = createSelector(
  (state: RootState) => state,
  (state) => state.subpaths
);

export const getSubpathsScans = createSelector(
  subPaths,
  (state) => state.scans
);
