const LOCAL_STORAGE_ACTIVE_USER_TOKEN_KEY = "xsignal.io-active-user-token";
const LOCAL_STORAGE_ACTIVE_KEYCLOAK_TOKEN_KEY =
  "xsignal.io-active-keycloak-token";
const LOCAL_STORAGE_ACTIVE_USER_TOKEN_VERSION = 1;

export const retrieveActiveUserToken = () => {
  const userTokenJson =
    process.env.REACT_APP_SECURITY === "okta"
      ? window.localStorage.getItem(LOCAL_STORAGE_ACTIVE_USER_TOKEN_KEY)
      : window.localStorage.getItem(LOCAL_STORAGE_ACTIVE_KEYCLOAK_TOKEN_KEY);

  if (!userTokenJson) {
    return undefined;
  }

  try {
    const parsed = JSON.parse(userTokenJson);
    if (
      parsed &&
      parsed.version &&
      parsed.version === LOCAL_STORAGE_ACTIVE_USER_TOKEN_VERSION
    ) {
      return parsed.data;
    }
  } catch (e) {
    console.error(e);
  }

  return undefined;
};

export const storeActiveUserToken = (
  jwt: string,
  refreshToken?: string,
  idToken?: string
) => {
  if (process.env.REACT_APP_SECURITY === "okta") {
    window.localStorage.setItem(
      LOCAL_STORAGE_ACTIVE_USER_TOKEN_KEY,
      JSON.stringify({
        version: LOCAL_STORAGE_ACTIVE_USER_TOKEN_VERSION,
        data: jwt,
      })
    );
  } else {
    window.localStorage.setItem(
      LOCAL_STORAGE_ACTIVE_KEYCLOAK_TOKEN_KEY,
      JSON.stringify({
        version: LOCAL_STORAGE_ACTIVE_USER_TOKEN_VERSION,
        data: jwt,
        refreshToken,
        idToken,
      })
    );
  }
};

export const clearActiveUserToken = () => {
  if (process.env.REACT_APP_SECURITY === "okta") {
    window.localStorage.setItem(LOCAL_STORAGE_ACTIVE_USER_TOKEN_KEY, "{}");
  } else {
    window.localStorage.setItem(LOCAL_STORAGE_ACTIVE_KEYCLOAK_TOKEN_KEY, "{}");
  }
};
