import {
  Breadcrumbs,
  Link,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useHistory } from "react-router";

const LinkStyle = withStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      color: theme.palette.black.contrastText,
      textDecoration: "underline",
    },
  },
}))(Link);

export type BreadcrumbsLayoutProps = {
  units: BreadcrumbsLayoutUnit[];
};

export type BreadcrumbsLayoutUnit = {
  title: string | undefined;
  path?: string;
};

export const BreadcrumbsLayout = ({ units }: BreadcrumbsLayoutProps) => {
  const history = useHistory();
  const isNotComplete: boolean = units.some((unit) => !Boolean(unit.title));

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {!isNotComplete &&
        units.map((unit, index) =>
          unit.path ? (
            <LinkStyle
              color="inherit"
              key={index}
              onClick={() => history.push(unit.path || "/")}
            >
              {unit.title}
            </LinkStyle>
          ) : (
            <Typography key={index} color="textPrimary">
              {unit.title}
            </Typography>
          )
        )}
    </Breadcrumbs>
  );
};
