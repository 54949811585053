import { createReducer } from "utils/createReducers";
import * as constants from "../constants/history";

const initState = {
  data: {},
  cves: {},
};

export default createReducer(initState, {
  [constants.getLastCVE]: (state, { payload }: any) => ({
    ...state,
    data: payload,
  }),
  [constants.GET_CVE_BY_NAME]: (state, { payload }: any) => ({
    ...state,
    cves: {
      ...state.cves,
      [payload.id]: payload.data,
    },
  }),
});
