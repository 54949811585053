import InfoPage from "../components/pages/Info/InfoPage";
import { connect } from "react-redux";
import { RootState } from "../store";
import CloudIcon from "../components/comps/icons/CloudIcon";

export default connect(
  (state: RootState) => ({
    content: [
      {
        icon: CloudIcon,
        ru: {
          title: "Подсети",
          child: [
            {
              title: "Что такое подсети?",
              href: "/subnetWhat",
            },
            {
              title: "Что такое диапазоны?",
              href: "/subnetRange",
            },
          ],
        },
        en: {
          title: "Subnet",
          child: [
            {
              title: "What are subnets?",
              href: "/subnetWhat",
            },
            {
              title: "What are ranges?",
              href: "/subnetRange",
            },
          ],
        },
      },
    ],
  }),
  {}
)(InfoPage);
