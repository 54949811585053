import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useMemo } from "react";
import { Box, createStyles, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { RootState } from "store";
import { useTranslation } from "react-i18next";
import { Asset } from "models/Asset";
import { getAssetsThunk } from "../../../../../store/action/assets";

const useStyles = makeStyles((theme) =>
  createStyles({
    premium: {
      backgroundColor: theme.palette.high.main,
      color: theme.palette.white.main,
    },
    basic: {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.white.main,
    },
    free: {
      backgroundColor: theme.palette.grey.A100,
      color: theme.palette.grey.A400,
    },
    capitalize: {
      textTransform: "capitalize",
    },
    container: {
      backgroundColor: theme.palette.white.main,
      padding: "24px 30px",
      margin: "0 0 24px 0",
    },
    title: {
      fontSize: "24px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    keyTitle: {
      textTransform: "capitalize",
    },
    valueTitle: {
      color: "rgba(0, 0, 0, 0.87)",
      textTransform: "capitalize",
    },
  })
);

const DashboardCommonInfo = forwardRef(() => {
  const [t] = useTranslation(["pageDashboard", "pageSettings"]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const settings: any = useSelector((state: RootState) => state.settings);
  const assets: Asset[] = useSelector((state: RootState) => state.assets.main);
  const scans: any = useSelector((state: RootState) => state.scanList.ids);

  const assetsActive = useMemo(() => {
    return assets?.filter((item) => !Boolean(item?.archive));
  }, [assets]);

  useEffect(() => {
    dispatch(getAssetsThunk());
  }, []);

  return (
    <>
      <Box className={classes.container}>
        <Typography className={classes.title}>
          {t("pageSettings:licenseInfo")}
        </Typography>

        <Box marginTop={"16px"}>
          <Typography className={classes.keyTitle}>
            {t("pageDashboard:tariff")}
          </Typography>
          <Typography className={classes.valueTitle}>
            {t(`subscription_plan.${settings.subscriptionPlan.toLowerCase()}`)}
          </Typography>
        </Box>
        <Box marginTop={"16px"}>
          <Typography className={classes.keyTitle}>
            {t("pageDashboard:countAsset")}
          </Typography>
          <Typography
            className={classes.valueTitle}
          >{`${assetsActive.length}/${settings.assetCount}`}</Typography>
        </Box>
        <Box marginTop={"16px"}>
          <Typography className={classes.keyTitle}>
            {t("pageDashboard:countScan")}
          </Typography>
          <Typography
            className={classes.valueTitle}
          >{`${scans.length}/${settings.scanCount}`}</Typography>
        </Box>
      </Box>
    </>
  );
});

export default DashboardCommonInfo;
