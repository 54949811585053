import React, { useContext, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";

import UserContext from "../../UserContext";
import { clearActiveUserToken, retrieveActiveUserToken } from "./auth";
import { profileLoadingMe } from "../../api/api";
import MainLoader from "../comps/Loader/MainLoader";

export const UserProfileLoading = (props: any) => {
  const oktaObject = useOktaAuth();

  useEffect(() => {
    if (process.env.REACT_APP_SECURITY === "okta") {
      if (oktaObject?.authState.isAuthenticated) {
        props.loadSettings();
      } else {
        if (!oktaObject?.authState.isPending) {
          window.location.replace("/");
        }
      }
    }
  }, [oktaObject?.authState]);

  const userContext = useContext(UserContext);
  const token = retrieveActiveUserToken();

  useEffect(() => {
    (async () => {
      try {
        // TODO: with axios interceptors not working
        const res = await profileLoadingMe();
        if (res?.data?.redirectToStripe) {
          window.location.replace(`${process.env.REACT_APP_URL_BILLING}`);
        }
        userContext.setActiveUserProfile({
          jwt: token,
          name: res.data.name,
          email: res.data.email,
        });
      } catch (e) {
        clearActiveUserToken();
      }
    })();
  }, [token, userContext]);

  return <MainLoader />;
};
