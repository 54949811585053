import { connect } from "react-redux";
import Dashboard from "../components/pages/Dashboard/Dashboard";
import { getVulnerabilitiesThunk } from "store/action/vulnerabilities";
import { RootState } from "../store";
import { loadScans } from "store/action/scanList";
import { selectScanList } from "store/reducers/scanList";

export default connect(
  (state: RootState) => ({
    vulnerData: state.vulnerabilities.dashboardData,
    vulnerTime: state.vulnerabilities.lastDate,
    assets: state.assets.main,
    scans: selectScanList(state),
  }),
  {
    getVulnerabilitiesThunk,
    loadScans,
  }
)(Dashboard);
