import { createTheme } from "@material-ui/core/styles";
import { enUS, ruRU } from "@material-ui/core/locale";
import i18n from "i18next";

const langs: {
  [key: string]: any;
} = {
  ru: ruRU,
  en: enUS,
};

// @ts-ignore
const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#FA49A0",
      },
      secondary: {
        main: "#2F4050",
      },
      white: {
        main: "#fff",
      },
      neutral: {
        main: "#f3f3f4",
      },
      backgroundGrey: {
        main: "#E5E5E5",
        contrastText: "#c4c4c4",
      },
      textGrey: {
        main: "#676a6c",
        contrastText: "rgba(0, 0, 0, 0.38);",
      },
      textDrawer: {
        main: "#a7b1c2",
      },
      dividerColor: {
        main: "#EEEEEE",
      },
      green: {
        main: "#00BF71",
      },
      blue: {
        main: "#C4D4FF",
      },
      yellow: {
        main: "#FCD736",
      },
      pink: {
        main: "#FA49A0",
      },
      lightGrey: {
        main: "#EEEEEE",
      },
      nightGrey: {
        main: "#0d242d",
        contrastText: "#273238",
      },
      critical: {
        main: "#ED5565",
      },
      high: {
        main: "#f8ac59",
      },
      medium: {
        main: "#23c6c8",
      },
      low: {
        main: "#1c84c6",
      },
      headerColor: {
        main: "#222",
      },
      backGrey: {
        main: "#F6F6F6",
        contrastText: "#FAFAFA",
      },
      greenIcon: {
        main: "#219653",
      },
      labelGrey: {
        main: "#9e9e9e",
      },
      black: {
        main: "#000",
        contrastText: "rgba(0,0,0, .87)",
      },
      tableFirstGrey: {
        main: "#e0e0e0",
      },
      tableYellowCell: {
        main: "#fa76b6;",
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            fontFamily: `"SuisseIntl", -apple-system, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
          }
        }
      },
      MuiGrid: {
        container: {
          width: "auto",
        },
      },
      MuiStepper: {
        root: {
          "&$completed": {
            color: "white",
          },
          "&$active": {
            color: "blue",
          },
          active: {
            color: "blue",
          },
        },
      },
    },
    typography: {
      fontFamily: `"SuisseIntl", -apple-system, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
      button: {
        textTransform: "none",
      },
      h4: {
        color: "#676a6c",
      },
      h5: {
        color: "#676a6c",
      },
      body1: {
        color: "#676a6c",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  },
  langs[i18n.language]
);

export default theme;
