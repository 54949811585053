import { FC } from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";

const Container = styled(Box)`
  background-color: #fff;
`;

const BoundaryLayout: FC = ({ children }) => {
  return (
    <Container
      position={"fixed"}
      left={"0"}
      top={"0"}
      zIndex={"9999"}
      height="100vh"
      width="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Container>
  );
};

export default BoundaryLayout;
