import AppBar from "@material-ui/core/AppBar";
import React, { FC, useContext, useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Typography,
  Hidden,
} from "@material-ui/core";
import ActiveUserBlock from "../../core/ActiveUserBlock";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import { fetchVulnerabilitiesStats } from "../../pages/Dashboard/data/dashboard.data";
import { VulnerabilityType } from "../../pages/Dashboard/core/dashboard.type";
import { HeaderContext } from "../../../context";
import MenuIcon from "@material-ui/icons/Menu";
import { Condition } from "../Condition";
import KeycloakHOC from "../Keycloak/KeycloakHOC";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.backgroundGrey.main,
      boxShadow: "none",
      height: "64px",
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.dividerColor.main}`,
      flexDirection: "row",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "0 21px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 10px",
      },
    },
    barOpen: {
      marginLeft: "250px",
      width: `calc(100% - 250px)`,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: 0,
      },
    },
    barClosed: {
      marginLeft: "40px",
      width: `calc(100% - 40px)`,
    },
    lvl: {
      fontSize: "20px",
      fontWeight: "bold",
      display: "flex",
    },
    headerColor: {
      color: theme.palette.headerColor.main,
      textTransform: "capitalize",
    },
    icon: {
      color: "rgba(0,0,0, .87)",
      cursor: "pointer",
    },
  })
);

const Header: FC<{
  withUser?: boolean;
  opened: boolean;
  setOpen: (val: boolean) => void;
  open: boolean;
}> = ({ withUser = false, opened, setOpen, open }) => {
  const oktaObject = useOktaAuth();
  const styles = useStyles();
  const [lvl, setLvl] = useState<string | undefined>(undefined);
  const [show, setShow] = useState(true);
  const h = useHistory();
  const headerContext = useContext(HeaderContext);

  const checkVulnerability = (res: any) => {
    setLvl(
      [
        VulnerabilityType.CRITICAL,
        VulnerabilityType.HIGH,
        VulnerabilityType.MEDIUM,
        VulnerabilityType.LOW,
      ].find((type) => {
        return res.some((r: any) => r.type === type && r.count !== 0);
      })
    );
  };

  useEffect(() => {
    if (h) {
      h.listen((e) => {
        setShow(e.pathname === "/");
        if (e.pathname === "/") {
          fetchVulnerabilitiesStats()
            .then((response) => {
              if (response) {
                checkVulnerability(response);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  }, []);

  useEffect(() => {
    setShow(h && h.location.pathname === "/");
    if (h && h.location.pathname === "/") {
      fetchVulnerabilitiesStats()
        .then((response) => {
          if (response) {
            checkVulnerability(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <AppBar
      position="fixed"
      className={`${styles.root} ${opened ? styles.barOpen : styles.barClosed}`}
      hidden={
        !oktaObject?.authState.isAuthenticated &&
        process.env.REACT_APP_SECURITY === "okta"
      }
    >
      <Box display={"flex"} alignItems={"center"}>
        <Hidden mdUp={true}>
          <Condition match={withUser}>
            <Box onClick={() => setOpen(!open)} display={"flex"}>
              <MenuIcon className={styles.icon} />
            </Box>
          </Condition>
        </Hidden>
        {headerContext.header?.length ? (
          <Box marginLeft={"23px"}>
            <Typography className={`${styles.lvl} ${styles.headerColor}`}>
              {headerContext.header}
            </Typography>
          </Box>
        ) : null}
      </Box>
      {/*<div>{*/}
      {/*  (lvl && show)*/}
      {/*    ? <div className={styles.lvl}*/}
      {/*           style={{color: VULNERABILITY_CONFIG[lvl].background}}>{t('thread_level')}: {(Icons as any)[lvl]} <span*/}
      {/*      style={{textTransform: 'uppercase'}}>{lvl}</span></div>*/}
      {/*    : null*/}
      {/*}</div>*/}
      <Condition match={withUser}>
        {process.env.REACT_APP_SECURITY === "keycloak" ? (
          <KeycloakHOC>
            <ActiveUserBlock />
          </KeycloakHOC>
        ) : (
          <ActiveUserBlock />
        )}
      </Condition>
    </AppBar>
  );
};

export default Header;
