import Keycloak from "keycloak-js";

const keycloak = Keycloak({
  url: "https://sso.bitsignal.1cbit.ru",
  realm: "xsignal",
  clientId: process.env.REACT_APP_ENV === "production" ? "app" : "react"
  // clientId: "react"
});

export default keycloak;
