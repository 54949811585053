import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import BoxView from "../../../../shared/BoxView";
import { VulnerabilityTagTableMain } from "../../../../shared/VulnerabilityTag";
import { Typography, Box } from "@material-ui/core";
import NotFoundComponent from "../../../../comps/InfoComponents/NotFoundComponent";
import StartModalComponent from "../../../../comps/InfoComponents/StartModalComponent";
import { useHistory } from "react-router-dom";
import MainTable from "../../../../comps/Table/MainTable";

const VulnerabilitiesDate: FC<{
  data: any[];
  hasVulnerabilities?: boolean;
  typeShowModal: string;
}> = ({ data, hasVulnerabilities, typeShowModal }) => {
  const history = useHistory();
  const [t] = useTranslation(["common", "pageAssets", "pageVulnerability"]);

  const cols = React.useMemo(
    () => [
      {
        Header: t("name"),
        accessor: "name",
        align: "left",
      },
      {
        Header: t("pageAssets:assets"),
        accessor: "hostname",
        align: "left",
      },
      {
        Header: t("severity"),
        accessor: "severity",
        align: "left",
        Cell: ({ row }: any) => {
          return <VulnerabilityTagTableMain value={row.original.severity} />;
        },
      },
      {
        Header: t("common:daysFromError"),
        accessor: "daysFromLastError",
        align: "left",
      },
    ],
    [t]
  );

  return (
    <BoxView title={t("common:vulnerabilitiesDate")} isLoading={false}>
      <Box
        style={
          typeShowModal !== "default"
            ? { height: "400px", position: "relative", background: "#fff" }
            : {}
        }
      >
        {typeShowModal !== "default" ? (
          <StartModalComponent
            title={t("pageVulnerability:vulnerabilityNotFound")}
            titleDescr={
              typeShowModal === "showAsset"
                ? t("pageVulnerability:findVulnerAsset")
                : t("pageVulnerability:findVulnerScan")
            }
            typeShowModal={typeShowModal}
            handler={() => {
              if (typeShowModal === "showAsset") {
                history.push("/assets");
              } else {
                history.push("/scans");
              }
            }}
          />
        ) : data.length ? (
          <>
            {/*<TableGrid*/}
            {/*  data={data}*/}
            {/*  columns={tableCols}*/}
            {/*  funcHandler={e => e}*/}
            {/*  cellsComponents={{*/}
            {/*    vulnerabilityTag: VulnerabilityTagTable*/}
            {/*  }}*/}
            {/*/>*/}
            <MainTable
              columns={cols}
              data={data}
              clickFunc={(f) => history.push(`/vulnerabilities/${f.id}`)}
            />
          </>
        ) : hasVulnerabilities ? (
          <Box display={"flex"} justifyContent={"center"} margin={"40px 0"}>
            <NotFoundComponent />
          </Box>
        ) : (
          <Typography>{t("common:notChecks")}</Typography>
        )}
      </Box>
    </BoxView>
  );
};

export default VulnerabilitiesDate;
