import { createReducer } from "../../utils/createReducers";
import {
  GET_VULNERABILITIES,
  GET_VULNERABILITIES_COUNT,
  GET_VULNERABILITIES_DATE,
  GET_VULNERABILITY_BY_ID,
  GET_VULNERABILITY_BY_ID_LOADING,
  GET_VULNERABILITY_BY_ID_CHECKS,
  GET_VULNERABILITY_HISTORY,
  GET_VULNERABILITY_MARKS,
} from "../constants/vulnerabilities";

const initialState = {
  dashboardData: [],
  allData: [],
  lastDate: [],
  vulnerabilityId: {},
  vulnerabilityIdChecks: {},
  vulnerabilityIdLoading: false,
  history: [],
  vulnerabilitiesMarks: {},
};

export default createReducer(initialState, {
  [GET_VULNERABILITIES]: (state, { payload }: any) => ({
    ...state,
    allData: payload,
  }),
  [GET_VULNERABILITIES_COUNT]: (state, { payload }: any) => {
    return {
      ...state,
      dashboardData: payload,
    };
  },
  [GET_VULNERABILITIES_DATE]: (state, { payload }: any) => ({
    ...state,
    lastDate: payload,
  }),
  [GET_VULNERABILITY_BY_ID]: (state, { payload, id }: any) => ({
    ...state,
    vulnerabilityId: {
      ...state.vulnerabilityId,
      [id]: payload,
    },
  }),
  [GET_VULNERABILITY_BY_ID_LOADING]: (state, { payload }: any) => ({
    ...state,
    vulnerabilityIdLoading: payload,
  }),
  [GET_VULNERABILITY_BY_ID_CHECKS]: (state, { payload, id }: any) => ({
    ...state,
    vulnerabilityIdChecks: {
      ...state.vulnerabilityIdChecks,
      [id]: payload,
    },
  }),
  [GET_VULNERABILITY_HISTORY]: (state, { payload }: any) => ({
    ...state,
    history: payload,
  }),
  [GET_VULNERABILITY_MARKS]: (state, { payload, id }: any) => ({
    ...state,
    vulnerabilitiesMarks: {
      ...state.vulnerabilitiesMarks,
      [id]: payload,
    },
  }),
});
